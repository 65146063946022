import React from 'react';
import { Grid } from 'semantic-ui-react';
import ChartDayByHours from './ChartThemed';

//ToDo trigger both charts with one legend
interface IProps {
  kpiArray: IKpiArray;
}
export default function SegmentChartKPIWrapper(props: IProps) {
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column className="smallChart">
          <ChartDayByHours
            overPlan={props.kpiArray.overPlan}
            underPlan={props.kpiArray.underPlan}
            inPlan={props.kpiArray.inPlan}
            hourChart={false}
          />
        </Grid.Column>
        <Grid.Column className="smallChart">
          <ChartDayByHours
            overPlan={props.kpiArray.overPlanMainChannel}
            underPlan={props.kpiArray.underPlanMainChannel}
            inPlan={props.kpiArray.inPlanMainChannel}
            hourChart={false}
            mainChannelChart={true}
            legend={false}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
