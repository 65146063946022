import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import {
  Chart,
  HighchartsChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ColumnSeries,
  HighchartsProvider,
} from 'react-jsx-highcharts';
import { _theme } from './theme';

interface IProps {
  hourChart: boolean;
  overPlan: any;
  underPlan: any;
  inPlan: any;
  mainChannelChart?: any;
  legend: any;
}
export default function ChartThemed(props: IProps) {
  var mobileDevice = window.innerHeight < 700 || window.innerWidth < 700;
  const [state, setState] = useState({
    portraitMode: true,
    mobileDevice: mobileDevice,
  });
  useEffect(() => {
    window.addEventListener('resize', updateOrientation);
    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    Highcharts.theme = _theme;
    Highcharts.setOptions(Highcharts.theme);
    if (props.hourChart) {
      updateOrientation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateOrientation() {
    if (window.innerHeight > window.innerWidth) {
      setState({
        ...state,
        portraitMode: true,
      });
    } else {
      setState({ ...state, portraitMode: false });
    }
  }
  const plotOptions = {
    series: {
      borderWidth: 0,
      stacking: 'normal' as any,
    },
  };
  let categories = [];
  if (props.hourChart) {
    categories = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];
  } else {
    if (props.mainChannelChart) {
      categories = ['HSE24'];
    } else {
      categories = ['EXT', 'TRE'];
    }
  }
  let yAxisFontSize = { fontSize: '', color: '#ffffff' };
  if (window.innerWidth < 350) {
    yAxisFontSize = { fontSize: '0.82em', color: '#ffffff' };
  }
  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart plotOptions={plotOptions}>
        {(!state.portraitMode && state.mobileDevice) || !props.hourChart ? (
          <Chart backgroundColor={'#1B1C1D'} />
        ) : (
          <Chart backgroundColor={'#1B1C1D'} inverted height={window.innerHeight * 0.9} />
        )}
        {props.legend && <Legend />}

        <Tooltip valueSuffix=" €" backgroundColor="rgba(27, 28, 29, 0.85)" split />
        <XAxis id="x" categories={categories} labels={{ step: 1 }} />

        <YAxis id="number" labels={{ step: 1, style: yAxisFontSize }}>
          <ColumnSeries id="overPlan" name="über MMFC" data={props.overPlan} />
          <ColumnSeries id="underPlan" name="unter MMFC" data={props.underPlan} />
          <ColumnSeries id="inPlan" name="erreicht" data={props.inPlan} />
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  );
}

ChartThemed.defaultProps = {
  legend: true,
  mainChannelChart: false,
};
