import Layout from '../Layout';
import classes from './TvProgram.module.scss';
import { createRef, useEffect, useState } from 'react';
import ProgramCard from '../ProgramCard/ProgramCard';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { FetchAllShows } from '../../redux/shows/showsActions';
import { RootState } from '../../redux/rootTypes';
import useQueryParams from '../../hooks/useQueryParams';
import { Channel, TvShow } from '../../types/tvShow';
import scrollIntoView from '../../utils/scrollIntoView';
import Header from '../Header/Header';
import Loading from '../UI/Loading';
import Error from '../UI/Error';
import { FetchSalesCountForShows } from "../../redux/sales/salesActions";

const TvProgram = () => {
  const dispatch = useDispatch();
  const { channel = Channel.HSE24 } = useQueryParams<{ channel: Channel; dateTime: string }>();

  const [dateTime, setDateTime] = useState(dayjs().hour(0).minute(0).toISOString());
  const { loading, error, showsList } = useSelector((RootState: RootState) => RootState.shows);

  const onAirRef = createRef<HTMLDivElement>();

  useEffect(() => {
    dispatch(new FetchAllShows(dateTime));
  }, [dateTime, dispatch]);

  useEffect(() => {
    if (showsList) {
      scrollIntoView(onAirRef);

      dispatch(new FetchSalesCountForShows(dateTime, showsList))
    }
  }, [dispatch, showsList, dateTime, onAirRef]);

  return (
    <Layout switcher={false}>
      <div className={classes.wrapper}>
        <Header />
        <div className={classes.title_bar}>
          <h3>{channel?.split('24').join(' ')} TV-Program</h3>
          <input
            className={classes.date_picker}
            type="date"
            onChange={e => setDateTime(dayjs(e.target.value).hour(0).minute(0).toISOString())}
            defaultValue={dateTime}
          />
        </div>

        <div className={classes.tv_shows_container}>
          {error && <Error />}
          {loading && <Loading />}
          {showsList &&
            showsList.map(
              (show: TvShow, key: number) =>
                show.channel === channel && (
                  <div key={key}>
                    {show.is_on_air && <div ref={onAirRef} />}
                    <ProgramCard program={show} />
                  </div>
                )
            )}
        </div>
      </div>
    </Layout>
  );
};

export default TvProgram;
