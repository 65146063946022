import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import cx from 'classnames';
import { RootState } from '../../redux/rootTypes';
import { DAY_FORMAT_ONLY } from '../../constants/constants';
import Loading from '../UI/Loading';
import Error from '../UI/Error';
import classes from './CrossSellsReport.module.scss';
import CrossSellsReportTable from './CrossSellsReportTable/CrossSellsReportTable';
import CrossSellsReportSearch from './CrossSellsReportSearch/CrossSellsReportSearch';
import { FetchCrossSellsReport } from '../../redux/crossSells/crossSellsActions';

const CrossSellsReport = () => {
  const dispatch = useDispatch();
  const monthStart = dayjs().startOf('month').format(DAY_FORMAT_ONLY);
  const today = dayjs().format(DAY_FORMAT_ONLY);
  const { loading, error } = useSelector((state: RootState) => state.crossSellsReport);

  useEffect(() => {
    dispatch(new FetchCrossSellsReport(monthStart, today));
  }, [dispatch, today, monthStart]);

  return (
    <div className={cx(classes.container, { [classes.loading_error]: loading || error })}>
      {loading && <Loading className={classes.loading} />}
      {error && <Error className={classes.error} />}
      <div
        className={cx(classes.table_wrapper, { [classes.table_loading_error]: loading || error })}
      >
        <CrossSellsReportSearch loading={loading} />
        {!loading && !error && <CrossSellsReportTable />}
      </div>
    </div>
  );
};

export default CrossSellsReport;