import React from 'react';
import { useSelector } from 'react-redux';
import { Segment, List } from 'semantic-ui-react';
import { RootState } from '../../../redux/rootTypes';
import ListItemShow from './ListItemShow';

interface IProps {
  secondTable?: boolean;
  addClassName: string;
}
export default function SegmentShowList(props: IProps) {
  const state = useSelector((state: RootState) => state.psw);
  var rows = [];
  var i = 0;
  var end = 12;
  var showData = state.showData;

  if (props.secondTable) {
    i = 12;
    end = 24;
  }

  for (i; i < end; i++) {
    rows.push(
      <ListItemShow
        key={'listItem' + i}
        showDataEntry={showData && showData[i]}
        urlQuery={state.urlQuery}
      />
    );
  }
  return (
    <Segment basic className={props.addClassName} style={{background: "white"}}>
      {props.secondTable ? (
        <List divided relaxed className="secondShowTable">
          {rows}
        </List>
      ) : (
        <List divided relaxed className="firstShowTable">
          {rows}
        </List>
      )}
    </Segment>
  );
}
SegmentShowList.defaultProps = {
  secondTable: false,
};
