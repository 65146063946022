import { Action } from 'redux';
import { ReklaOrdersReportActionTypes } from './reklaOrdersTypes';
import { ReklaOrdersReport } from '../../types/reklaOrdersReport';

export class FetchReklaOrdersReport implements Action {
  type = ReklaOrdersReportActionTypes.FETCH_REKLA_ORDERS_REPORT_DATA;
  payload: {
    startDate: string;
    endDate: string;
  };

  constructor(startDate: string, endDate: string) {
    this.payload = {
      startDate,
      endDate,
    };
  }
}

export class FetchReklaOrdersReportSucceeded implements Action {
  type = ReklaOrdersReportActionTypes.FETCH_REKLA_ORDERS_REPORT_SUCCEEDED;

  payload: {
    report: ReklaOrdersReport;
  };

  constructor(report: ReklaOrdersReport) {
    this.payload = {
      report,
    };
  }
}

export class FetchReklaOrdersReportFailed implements Action {
  type = ReklaOrdersReportActionTypes.FETCH_REKLA_ORDERS_REPORT_FAILED;
}
