import { all, call, put, takeLatest } from 'redux-saga/effects';
import API from '../../api/api';
import {
  FetchSalesCount,
  FetchSalesCountFailed,
  FetchSalesCountForShows,
  FetchSalesCountForShowsFailed,
  FetchSalesCountForShowsSucceeded,
  FetchSalesCountSucceeded
} from './salesActions';
import { SalesActionTypes } from './salesTypes';
import { AxiosResponse } from "axios";
import { TvShow } from "../../types/tvShow";
import { SalesCount } from "../../types/salesCount";

export function* getSalesCount(action: FetchSalesCount) {
  const { payload } = action;
  try {
    const { data } = yield call(API.getSalesCount, payload.dateTime, payload.productsBaseNo);
    yield put(new FetchSalesCountSucceeded(data));
  } catch (err) {
    yield put(new FetchSalesCountFailed());
  }
}

export function* getSalesCountForShows(action: FetchSalesCountForShows) {
  const { payload } = action;
  try {

    const shows: AxiosResponse<TvShow>[] = yield all(payload.shows.map(s => {
      return call(API.getProductsByShowId, s.id)
    }));

    const salesCount: AxiosResponse<SalesCount>[] = yield all(shows.map(s => {
      const products = s.data.products?.map(p => p.base_product_no) ?? []
      if (products.length) {
        return call(API.getSalesCount, s.data.date_time, products)
      }
      return undefined;
    }));

    const total = salesCount
      .filter(v => v !== undefined)
      .reduce((a, v) => {
        return a + v.data.items.reduce((aa, vv) => aa + vv.total_revenue, 0)
      }, 0)

    yield put(new FetchSalesCountForShowsSucceeded(total));
  } catch (err) {
    yield put(new FetchSalesCountForShowsFailed());
  }
}

export default function* productSagasWatcher() {
  yield takeLatest(SalesActionTypes.FETCH_SALES_COUNT, getSalesCount);
  yield takeLatest(SalesActionTypes.FETCH_SALES_COUNT_FOR_SHOWS, getSalesCountForShows);
}
