import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectAgenciesCrossSellConversion,
  selectAgenciesTotalCrossSellOrders,
  selectAgenciesTotalCrossSellRevenue,
  selectAgenciesTotalOrders,
  selectAgenciesTotalRevenue,
  selectAgencyCrossSellConversion,
  selectAgencySales,
} from '../../../redux/agency/agencySelectors';
import { RootState } from '../../../redux/rootTypes';

import styles from './AgencyHeader.module.scss';

const Test = () => {
  const { currentAgency } = useSelector((state: RootState) => state.agency);
  const agencySales = useSelector(selectAgencySales(currentAgency));
  const agencyCrossSellConversion = useSelector(selectAgencyCrossSellConversion(currentAgency));
  const totalAgenciesOrders = useSelector(selectAgenciesTotalOrders());
  const totalAgenciesRevenue = useSelector(selectAgenciesTotalRevenue());
  const totalAgenciesCrossSellOrders = useSelector(selectAgenciesTotalCrossSellOrders());
  const totalAgenciesCrossSellRevenue = useSelector(selectAgenciesTotalCrossSellRevenue());
  const agenciesCrossSellConversion = useSelector(selectAgenciesCrossSellConversion());

  return (
    <table className={styles.container}>
      <thead>
        <tr>
          <th></th>
          <th scope="col">{currentAgency}</th>
          <th scope="col">Total </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Anzahl Bestellungen</td>
          <td>{agencySales?.total_orders}</td>
          <td>{totalAgenciesOrders}</td>
        </tr>

        <tr>
          <td>Umsatz</td>
          <td>{agencySales?.total_price}€</td>
          <td>{totalAgenciesRevenue}€</td>
        </tr>

        <tr>
          <td>Cross-Sell Items</td>
          <td>{agencySales?.cross_sell_orders}</td>
          <td>{totalAgenciesCrossSellOrders}</td>
        </tr>

        <tr>
          <td>Umsatz</td>
          <td>{agencySales?.cross_sell_price}€</td>
          <td>{totalAgenciesCrossSellRevenue}€</td>
        </tr>

        <tr>
          <td>Cross-Sell conversion</td>
          <td>{agencyCrossSellConversion}</td>
          <td>{agenciesCrossSellConversion}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Test;
