import { RootState } from '../rootTypes';
import { Channel, ChannelInfo, ChannelCode } from '../../types/tvShow';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export const selectOnAirShow = (channel: Channel) => (state: RootState) =>
  state.shows.showsList.find(show => show.is_on_air === true && show.channel === channel);

export const selectShowExtraInfo = (date: string) => (state: RootState) => {
  const show =
    state.shows.shows_revenue &&
    state.shows.shows_revenue.show.find(e => e.show_hour === parseInt(dayjs(date).format('H')));
  return show;
};

export const selectShowExpectedRevenue = (date: string) => () => {
  const show = useSelector(selectShowExtraInfo(date));
  return show ? show.fs_hour_mmfc : 0;
};

export const selectShowGeneratedRevenue = (date: string) => () => {
  const show = useSelector(selectShowExtraInfo(date));
  return show ? show.fs_hour_act : 0;
};

export const selectChannelExpectedRevenue = (channel: ChannelCode) => (state: RootState) => {
  const channelInfo = state.shows.channels_daily_revenue.day_view_responses.find(
    (e: ChannelInfo) => e.sha_code === channel
  );
  return channelInfo?.fsp;
};

export const selectChannelGeneratedRevenue = () => (state: RootState) => {
  return state.shows.shows_revenue.show.reduce(function (accumulator, item) {
    return accumulator + item.fs_hour_act;
  }, 0);
};
