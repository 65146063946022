import React, { useState } from 'react';
import classes from './ReportTable.module.scss';
import TableHeader from '../TableHeader/TableHeader';
import { getComparator, tableSort } from '../../utils/tableSorter';
import dayjs from 'dayjs';

export type Order = 'asc' | 'desc';

interface ReportTableProps {
  headers: { label: string, id: string }[];
  data: any[];
  uniqueKey: string;
}

const ReportTable: React.FC<ReportTableProps> = ({ headers, data, uniqueKey }) => {
  const [order, setOrder] = useState<Order>('asc');
  const [sortBy, setSortBy] = useState<string>('');

  const handleRequestSort = (property: string) => {
    const isAsc = sortBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  return (
    <div className={classes.container}>
      <table className={classes.table_container}>
        <TableHeader
          sortBy={sortBy}
          headCells={headers}
          onRequestSort={handleRequestSort}
          order={order}
        />
        <tbody>
        {tableSort(data, getComparator(order, sortBy)).map((item) => (
          <tr key={item[uniqueKey]}>
            {headers.map(header =>
              header.id === 'order_date' ?
                <td key={header.id}>{dayjs(item[header.id]).format('YYYY-MM-DD HH:mm')}</td> :
                <td key={header.id}>{item[header.id]}</td>,
            )}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
