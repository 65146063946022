import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../api/api';
import {
  FetchShowsDataSucceeded,
  FetchShowsDataFailed,
  FetchShowsRevenue,
  FetchShowsRevenueSucceeded,
  FetchShowsRevenueFailed,
  FetchChannelDailyRevenue,
  FetchChannelDailyRevenueSucceeded,
  FetchChannelDailyRevenueFailed,
} from './showsActions';
import { ShowsActionTypes } from './showsTypes';
import { FetchAllShows } from './showsActions';
import dayjs from 'dayjs';
import { DEFAULT_DATETIME_FORMAT } from '../../constants/constants';

export interface Props {
  startDate: string;
  endDate: string;
}

export function* getAllShows(action: FetchAllShows) {
  const { payload } = action;

  try {
    const { data } = yield call(
      API.getTvShowList,
      dayjs(payload).format(DEFAULT_DATETIME_FORMAT),
      dayjs(payload).add(1, 'day').format(DEFAULT_DATETIME_FORMAT)
    );
    yield put(new FetchShowsDataSucceeded(data));
  } catch (err) {
    yield put(new FetchShowsDataFailed());
  }
}

export function* getShowsRevenue(action: FetchShowsRevenue) {
  const { payload } = action;

  try {
    const { data } = yield call(API.getShowsRevenue, payload.day, payload.channel);
    yield put(new FetchShowsRevenueSucceeded(data));
  } catch (err) {
    yield put(new FetchShowsRevenueFailed());
  }
}

export function* getChannelDailyRevenue(action: FetchChannelDailyRevenue) {
  const { payload } = action;
  try {
    const { data } = yield call(API.getChannelsDailyRevenue, payload.day);
    yield put(new FetchChannelDailyRevenueSucceeded(data));
  } catch (err) {
    yield put(new FetchChannelDailyRevenueFailed());
  }
}

export default function* showsSagasWatcher() {
  yield takeLatest(ShowsActionTypes.FETCH_ALL_SHOWS, getAllShows);
  yield takeLatest(ShowsActionTypes.FETCH_SHOWS_REVENUE, getShowsRevenue);
  yield takeLatest(ShowsActionTypes.FETCH_CHANNELS_DAILY_REVENUE, getChannelDailyRevenue);
}
