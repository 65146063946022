import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootTypes';
import Loading from '../../UI/Loading';
import Error from '../../UI/Error';
import { CountryCode, PriceInfo } from '@hse24/shared-components';
import {
  selectCrossSell,
  selectOnlineShopSell,
  selectTotalOrdered,
  selectTotalRevenue,
  selectTvSales,
} from '../../../redux/sales/salesSelectors';

interface Props {
  productBaseNo: string;
}

const StockColumns = ({ productBaseNo }: Props) => {
  const { salesCountLoading, salesCountError } = useSelector(
    (RootState: RootState) => RootState.sales
  );

  const tvSales = useSelector(selectTvSales(productBaseNo));
  const onlineShop = useSelector(selectOnlineShopSell(productBaseNo));
  const crossSell = useSelector(selectCrossSell(productBaseNo));
  const totalOrdered = useSelector(selectTotalOrdered(productBaseNo));
  const totalRevenue = useSelector(selectTotalRevenue(productBaseNo));

  return (
    <>
      {salesCountLoading && (
        <>
          <td>
            <Loading />
          </td>
          <td>
            <Loading />
          </td>
          <td>
            <Loading />
          </td>
          <td>
            <Loading />
          </td>
          <td>
            <Loading />
          </td>
        </>
      )}
      {salesCountError && (
        <>
          <td>
            <Error />
          </td>
          <td>
            <Error />
          </td>
          <td>
            <Error />
          </td>
          <td>
            <Error />
          </td>
          <td>
            <Error />
          </td>
        </>
      )}
      {!salesCountError && !salesCountLoading && (
        <>
          <td>{totalOrdered}</td>
          <td>{tvSales}</td>
          <td>{onlineShop}</td>
          <td>{crossSell}</td>
          <td>
            <PriceInfo countryCode={CountryCode.DE} value={totalRevenue} />
          </td>
        </>
      )}
    </>
  );
};

export default StockColumns;
