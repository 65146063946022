import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentProduct } from '../../redux/products/productSelectors';
import { CurrentProduct } from '../../types/product';
import { Channel } from '../../types/tvShow';
import ProductCard from '../ProductCard/ProductCard';
import classes from './ProductTable.module.scss';
import { RootState } from '../../redux/rootTypes';
import { FetchOnAirProduct } from '../../redux/products/productsActions';
import { AIR_TIME_DELAY } from '../../constants/constants';

interface Props {
  channel: Channel;
  dateTime: string;
}

const ProductTable = (props: Props) => {
  const currentProducts = useSelector(selectCurrentProduct);
  const { productOnAir } = useSelector((RootState: RootState) => RootState.product);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(new FetchOnAirProduct(props.channel, props.dateTime));
    const timer = setInterval(() => {
      dispatch(new FetchOnAirProduct(props.channel, props.dateTime));
    }, AIR_TIME_DELAY);
    return () => clearInterval(timer);
  }, [dispatch, props.channel, props.dateTime]);

  useEffect(() => {
    const ScrollToLiveProduct = () => {
      const slideNode = document.getElementById('scroll');
      slideNode && slideNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    ScrollToLiveProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOnAir.product_nos, document.getElementById('scroll')]);

  return (
    <div className={classes.product_table_container}>
      <table>
        <thead>
          <tr>
            <th>Position</th>
            <th>SKU</th>
            <th>Name</th>
            <th>Preis</th>
            <th>Type</th>
            <th>Bestand</th>
            <th>Verkauft</th>
            <th>TV-Verkauf</th>
            <th>Online-Verkauf</th>
            <th>UpSell-Verkauf</th>
            <th>Revenue</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentProducts?.map((product: CurrentProduct, key: number) => {
            return (
              <ProductCard
                position={product.position}
                type={product.type}
                productBaseNo={product.base_product_no}
                channel={props.channel}
                key={key}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
