import { AgenciesActionTypes, AgenciesState } from './agencyTypes';

export const initialState: AgenciesState = {
  agencies: { start_date: '', end_date: '', call_centers_sales: [] },
  error: false,
  loading: true,
  currentAgency: '',
};

const showsReducer = (
  state = initialState,
  action: { type: string; payload?: any }
): AgenciesState => {
  switch (action.type) {
    case AgenciesActionTypes.FETCH_AGENCIES_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        agencies: action.payload.agencies,
      };
    case AgenciesActionTypes.FETCH_AGENCIES_FAILED:
      return { ...state, error: true, loading: false };

    case AgenciesActionTypes.CURRENT_AGENCY:
      return { ...state, currentAgency: action.payload.currentAgency };

    case AgenciesActionTypes.FETCH_AGENCIES_LOADING:
      return {
        ...state,
        currentAgency: '',
        loading: true,
        error: false,
        agencies: { start_date: '', end_date: '', call_centers_sales: [] },
      };
    default:
      return state;
  }
};

export default showsReducer;
