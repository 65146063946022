import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Button, SemanticCOLORS } from 'semantic-ui-react';
import { RootState } from '../../../redux/rootTypes';
import * as changeHelper from './../data/onChange';
import {
  updateProgressAction,
  requestSuccessAction,
  onErrorAction,
} from '../../../redux/psw/pswActions';
import { pswRequest } from '../service/PSWAPIs';

const groups = ['SHOW', 'ADT', 'JDW', 'MI'];

export default function SegmentGroupBtn() {
  const initState = useSelector((state: RootState) => state.psw);
  const dispatch = useDispatch();

  const grp: string = initState.urlQuery.group;
  const [state, setState] = useState({ group: grp });

  const onGroupChange = async (newgroup: string) => {
    if (!initState.updateInProgress) {
      let changedUrlQuery = initState.urlQuery;
      if (newgroup === 'SHOW') {
        changedUrlQuery = changeHelper.onShowProductsChange(true, changedUrlQuery);
      } else {
        changedUrlQuery = changeHelper.onShowProductsChange(false, changedUrlQuery);
      }
      changedUrlQuery = changeHelper.onGroupChange(newgroup, changedUrlQuery);
      dispatch(updateProgressAction(true));

      pswRequest({ ...initState, urlQuery: changedUrlQuery })
        .then(data => {
          if (data) {
            dispatch(requestSuccessAction(data));
          } else {
            dispatch(onErrorAction());
          }
        })
        .catch(() => {
          dispatch(onErrorAction());
        });
    }
  };

  const onItemChange = (newgroup: string) => {
    console.info(`New Group: ${newgroup}`);

    if ((newgroup !== grp || initState.error) && !initState.updateInProgress) {
      setState({
        ...state,
        group: newgroup,
      });
      onGroupChange(newgroup).then();
    }
  };

  return (
    <Segment inverted textAlign="center" className="noMargin">
      {groups.map((group, index) => {
        const newGroupLoading = state.group !== initState.urlQuery.channel && state.group === group;

        if (group === grp) {
          return <GroupBtn key={index} color="orange" group={group} onItemChange={onItemChange} />;
        } else {
          return (
            <GroupBtn
              key={index}
              group={group}
              loading={newGroupLoading}
              onItemChange={onItemChange}
            />
          );
        }
      })}
    </Segment>
  );
}

interface GroupBtnProps {
  onItemChange: (newgroup: string) => void;
  group: string;
  color?: string;
  loading?: boolean;
}

function GroupBtn({ loading, onItemChange, group, color }: GroupBtnProps) {
  return (
    <Button
      key={group}
      inverted
      circular
      size="tiny"
      color={color as SemanticCOLORS}
      loading={loading}
      onClick={() => {
        if (!loading) {
          onItemChange(group);
        }
      }}
    >
      {group}
    </Button>
  );
}
