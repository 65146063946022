import { Action } from 'redux';
import { CrossSellsReportActionTypes } from './crossSellsTypes';
import { CrossSellsReport } from '../../types/crossSellsReport';

export class FetchCrossSellsReport implements Action {
  type = CrossSellsReportActionTypes.FETCH_CROSS_SELLS_REPORT_DATA;
  payload: {
    startDate: string;
    endDate: string;
  };
  constructor(startDate: string, endDate: string) {
    this.payload = {
      startDate,
      endDate,
    };
  }
}

export class FetchCrossSellsReportSucceeded implements Action {
  type = CrossSellsReportActionTypes.FETCH_CROSS_SELLS_REPORT_SUCCEEDED;

  payload: {
    report: CrossSellsReport;
  };

  constructor(report: CrossSellsReport) {
    this.payload = {
      report,
    };
  }
}

export class FetchCrossSellsReportFailed implements Action {
  type =  CrossSellsReportActionTypes.FETCH_CROSS_SELLS_REPORT_FAILED;
}
