import { RootState } from '../rootTypes';
import { SingleProductState } from '../../types/product';
import { Item } from '../../types/salesCount';

export const selectSingleProductInfo = (productBaseNo: string) => (state: RootState) => {
  return state.product.productsByNo.find(
    (product: SingleProductState) => product.baseProductNo === productBaseNo
  );
};

export const selectVariantsSales = (productBaseNo: string) => (state: RootState) => {
  const productsSales = state.sales.productsSalesCount.items.find(
    (item: Item) => item.base_product_number === productBaseNo
  );

  return productsSales ? productsSales.variants : [];
};

export const selectCurrentProduct = (state: RootState) => state.product.productsByShowIdData.products;
