import { CountryCode, PriceInfo } from '@hse24/shared-components';
import { useSelector } from 'react-redux';
import {
  selectVariantCrossSell,
  selectVariantOnlineShopSell,
  selectVariantTotalRevenue,
  selectVariantTvSales,
} from '../../../redux/sales/salesSelectors';
import { Variant, Dimensions, VariantType, Dimension } from '../../../types/product';
import { VariantsSales } from '../../../types/salesCount';
import { getHexVariant } from '../../../utils/getHexVariant';
import { getImageUrl } from '../../../utils/getImageUrl';
import classes from './VariantCard.module.scss';

interface Props {
  variant: Variant;
  stock?: { sku: string; amount: number; reserved_amount: number };
  dimensions?: Dimensions[];
  currentVariantSales: VariantsSales[];
}

interface ColorProps {
  color: string;
  dimensions?: Dimensions;
}

const ColorCircle = ({ color, dimensions }: ColorProps) => {
  return (
    <div
      className={classes.color_circle}
      style={{ backgroundColor: `#${getHexVariant(color, dimensions)}` }}
    />
  );
};

const VariantCard = ({ variant, stock, dimensions, currentVariantSales }: Props) => {
  const tvSales = useSelector(selectVariantTvSales(variant.sku));
  const onlineShop = useSelector(selectVariantOnlineShopSell(variant.sku));
  const crossSell = useSelector(selectVariantCrossSell(variant.sku));
  const totalRevenue = useSelector(selectVariantTotalRevenue(variant.sku));
  return (
    <tr className={classes.variant_card}>
      <td className={classes.sku} data-testid="sku">
        {variant.sku}
      </td>
      <td className={classes.basic_info}>
        <img src={variant.images && getImageUrl(variant.images[0].uri)} alt="" />
        <div className={classes.dimensions}>
          {variant.dimensions.map((dim: Dimension, key: number) => (
            <div className={classes.dim} key={key}>
              <p>{dim.type}:</p>
              {dim.type === VariantType.COLOR && (
                <ColorCircle
                  color={dim.value}
                  dimensions={dimensions?.find((el: Dimensions) => el.type === VariantType.COLOR)}
                />
              )}
              <p>{dim.value}</p>
            </div>
          ))}
        </div>
      </td>
      <td>
        <PriceInfo
          countryCode={CountryCode.DE}
          className={classes.price}
          value={variant.price.value}
          reference={{
            value: variant.price.reference ? variant.price.reference.value : 0,
          }}
        />
      </td>
      <td>
        {stock && (
          <div className={classes.stock}>
            <span>{stock.amount}</span> <span>{stock.reserved_amount}</span>
          </div>
        )}
      </td>
      <td>{tvSales}</td>
      <td>{onlineShop}</td>
      <td>{crossSell}</td>
      <td>{totalRevenue}</td>
    </tr>
  );
};
export default VariantCard;
