import { Channel } from '../../types/tvShow';
import classes from './GoToLive.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectOnAirShow } from '../../redux/shows/showsSelectors';
import { Button, EInputTypes } from '@hse24/shared-components';
import { push } from 'connected-react-router';
import Config from "../../config/config";

interface GoToLiveProps {
  channel: Channel;
}

const GoToLive = ({ channel }: GoToLiveProps) => {
  const liveShow = useSelector(selectOnAirShow(channel));
  const dispatch = useDispatch();

  return (
    <Button
      className={classes.go_to_live}
      onClick={() => {
        dispatch(
          push(
            `${Config.rootRoute}/show-reporting/${liveShow?.id}/?date_time=${liveShow?.date_time}&channel=${channel}`
          )
        );
      }}
      inputType={EInputTypes.submit}
    >
      Go to current show
    </Button>
  );
};
export default GoToLive;
