import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../api/api';
import {
  FetchProductsStock,
  FetchProductsStockFailed,
  FetchProductsStockSucceeded,
} from './stockAction';
import { StockActionType } from './stockTypes';
import splitArrayIntoChunks from "../../utils/splitArrayIntoChunks";
import {ProductsStock} from "../../types/stock";

const maxBpnosPerRequest = 60;

export function* getProductsStock(action: FetchProductsStock) {
  const { payload } = action;
  try {
    const splitBpnos = splitArrayIntoChunks(payload.productsBaseNo, maxBpnosPerRequest);
    const productsStock: ProductsStock = {stock: [], waitlist_stock: []};
    for (const bpnos of splitBpnos) {
    const { data } = yield call(API.getProductsStock, bpnos);
      productsStock.stock.push(...data.stock);
      productsStock.waitlist_stock.push(...data.waitlist_stock);
    }
    yield put(new FetchProductsStockSucceeded(productsStock));
  } catch (err) {
    yield put(new FetchProductsStockFailed());
  }
}

export default function* productSagasWatcher() {
  yield takeLatest(StockActionType.FETCH_PRODUCTS_STOCK, getProductsStock);
}
