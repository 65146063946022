import { ReklaOrdersReportState, ReklaOrdersReportActionTypes } from './reklaOrdersTypes';

export const initialState: ReklaOrdersReportState = {
  report: { 'date_range': { 'start_date': '', end_date: '' }, orders: [] },
  error: false,
  loading: true,
};

const reklaOrdersReportReducer = (
  state = initialState,
  action: { type: string; payload?: any },
): ReklaOrdersReportState => {
  switch (action.type) {
    case ReklaOrdersReportActionTypes.FETCH_REKLA_ORDERS_REPORT_SUCCEEDED:
      return {
        loading: false,
        error: false,
        report: action.payload.report,
      };
    case ReklaOrdersReportActionTypes.FETCH_REKLA_ORDERS_REPORT_FAILED:
      return { ...state, error: true, loading: false };

    case ReklaOrdersReportActionTypes.FETCH_REKLA_ORDERS_REPORT_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reklaOrdersReportReducer;
