import { applyMiddleware, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { composeWithDevTools as composeWithLogOnly } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer, reduxInitialState } from '../redux/rootReducer';
import allSagas from '../redux/rootSaga';
import Config from '../config/config';
import { History } from 'history';
import { Environment } from '../types/environment';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const convertActionInstanceToObject: Middleware = () => next => action => {
  const { type, payload } = action;
  return next({ type, payload });
};

function composeEnhancers(history: History) {
  const middlewares = [convertActionInstanceToObject, sagaMiddleware, routerMiddleware(history)];
  const enhancers = [applyMiddleware(...middlewares)];

  if (Config.env === Environment.Local) {
    return composeWithDevTools(...enhancers);
  }
  return composeWithLogOnly(...enhancers);
}

export function configureStore(history: History) {
  // @ts-ignore
  const store = createStore(
    createRootReducer(history),
    reduxInitialState,
    composeEnhancers(history)
  );

  allSagas.map(saga => sagaMiddleware.run(saga));

  return store;
}
