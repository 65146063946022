import { SalesActionTypes } from './salesTypes';
import { Action } from 'redux';
import { SalesCount } from '../../types/salesCount';
import { TvShow } from "../../types/tvShow";

export class FetchSalesCount implements Action {
  type = SalesActionTypes.FETCH_SALES_COUNT;
  payload: {
    dateTime: string;
    productsBaseNo: string[];
  };
  constructor(dateTime: string, productsBaseNo: string[]) {
    this.payload = {
      dateTime,
      productsBaseNo,
    };
  }
}

export class FetchSalesCountSucceeded implements Action {
  payload: {
    productsSalesCount: SalesCount;
  };
  type = SalesActionTypes.FETCH_SALES_COUNT_SUCCEEDED;

  constructor(productsSalesCount: SalesCount) {
    this.payload = {
      productsSalesCount,
    };
  }
}

export class FetchSalesCountFailed implements Action {
  type = SalesActionTypes.FETCH_SALES_COUNT_FAILED;
}

export class FetchSalesCountForShows implements Action {
  type = SalesActionTypes.FETCH_SALES_COUNT_FOR_SHOWS;
  payload: {
    dateTime: string;
    shows: TvShow[];
  };
  constructor(dateTime: string, shows: TvShow[]) {
    this.payload = {
      dateTime,
      shows,
    };
  }
}

export class FetchSalesCountForShowsSucceeded implements Action {
  payload: {
    channelTotalRevenue: number;
  };
  type = SalesActionTypes.FETCH_SALES_COUNT_SUCCEEDED_FOR_SHOWS;

  constructor(channelTotalRevenue: number) {
    this.payload = {
      channelTotalRevenue,
    };
  }
}

export class FetchSalesCountForShowsFailed implements Action {
  type = SalesActionTypes.FETCH_SALES_COUNT_FAILED_FOR_SHOWS;
}
