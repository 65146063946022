import classes from './Revenue.module.scss';
import { CountryCode, Divider, PriceInfo } from '@hse24/shared-components';

interface Props {
  expectedRevenue: number;
  generatedRevenue: number;
}

const Revenue = ({ expectedRevenue, generatedRevenue }: Props) => {
  return (
    <div className={classes.revenue_container}>
      <div className={classes.generated_revenue}>
        <h4>Generated Revenue</h4>
        <PriceInfo
          className={classes.price}
          countryCode={CountryCode.DE}
          value={generatedRevenue}
        />
      </div>
      <Divider className={classes.divider} />

      <div className={classes.expected_revenue}>
        <h4>Expected Revenue</h4>
        <PriceInfo className={classes.price} countryCode={CountryCode.DE} value={expectedRevenue} />
      </div>
    </div>
  );
};

export default Revenue;
