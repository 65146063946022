import { ShowsActionTypes } from './showsTypes';
import { Action } from 'redux';
import { ChannelsDailyRevenue, ShowsRevenue, TvShowsResponse } from '../../types/tvShow';

export class FetchAllShows implements Action {
  type = ShowsActionTypes.FETCH_ALL_SHOWS;
  payload: string;

  constructor(dateTime: string) {
    this.payload = dateTime;
  }
}

export class FetchShowsDataSucceeded implements Action {
  payload: {
    data: TvShowsResponse;
  };
  type = ShowsActionTypes.FETCH_SHOWS_DATA_SUCCEEDED;

  constructor(data: TvShowsResponse) {
    this.payload = {
      data,
    };
  }
}

export class FetchShowsDataFailed implements Action {
  type = ShowsActionTypes.FETCH_SHOWS_DATA_FAILED;
}

export class FetchShowsRevenue implements Action {
  payload: {
    day: string;
    channel: string;
  };
  type = ShowsActionTypes.FETCH_SHOWS_REVENUE;

  constructor(day: string, channel: string) {
    this.payload = {
      day,
      channel,
    };
  }
}

export class FetchShowsRevenueSucceeded implements Action {
  payload: {
    data: ShowsRevenue;
  };
  type = ShowsActionTypes.FETCH_SHOWS_REVENUE_SUCCEEDED;

  constructor(data: ShowsRevenue) {
    this.payload = {
      data,
    };
  }
}

export class FetchShowsRevenueFailed implements Action {
  type = ShowsActionTypes.FETCH_SHOWS_REVENUE_FAILED;
}

export class FetchChannelDailyRevenue implements Action {
  payload: {
    day: string;
  };
  type = ShowsActionTypes.FETCH_CHANNELS_DAILY_REVENUE;

  constructor(day: string) {
    this.payload = {
      day,
    };
  }
}

export class FetchChannelDailyRevenueSucceeded implements Action {
  payload: {
    data: ChannelsDailyRevenue;
  };
  type = ShowsActionTypes.FETCH_CHANNELS_DAILY_REVENUE_SUCCEEDED;

  constructor(data: ChannelsDailyRevenue) {
    this.payload = {
      data,
    };
  }
}

export class FetchChannelDailyRevenueFailed implements Action {
  type = ShowsActionTypes.FETCH_CHANNELS_DAILY_REVENUE_FAILED;
}
