import dayjs from 'dayjs';
import { Channel, TvShow } from '../types/tvShow';

type SwitcherValue = {
  value: number;
  label: string;
};

export const getShowsByChannel = (showList: TvShow[], channel: Channel) => {
  const reduced = showList.reduce(function (filtered: SwitcherValue[], show: TvShow) {
    if (show.channel === channel) {
      const someNewValue = {
        value: show.id,
        label: `${show?.title} -- ${dayjs(show?.date_time).format('YYYY-MM-DD HH:mm')} `,
      };
      filtered.push(someNewValue);
    }
    return filtered;
  }, []);

  return reduced;
};
