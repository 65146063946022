import classes from './AgencySearch.module.scss';
import dayjs from 'dayjs';
import { Button, EInputTypes } from '@hse24/shared-components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FetchAgencies } from '../../../redux/agency/agencyActions';
const DAY_FORMAT_ONLY = 'YYYY-MM-DD';

const AgencySearch = () => {
  const monthStart = dayjs().startOf('month').format(DAY_FORMAT_ONLY);
  const today = dayjs().format(DAY_FORMAT_ONLY);

  const [startDate, setStartDate] = useState(monthStart);
  const [endDate, setEndDate] = useState(today);

  const dispatch = useDispatch();

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    dispatch(new FetchAgencies(startDate, endDate));
  };

  return (
    <form onSubmit={handleSubmit} className={classes.search_container}>
      <div className={classes.date}>
        <h4>Start Date :</h4>
        <input
          className={classes.date_picker}
          type="date"
          onChange={e => setStartDate(`${e.target.value}`)}
          value={startDate}
        />
      </div>

      <div className={classes.date}>
        <h4>End Date :</h4>
        <input
          className={classes.date_picker}
          type="date"
          onChange={e => setEndDate(`${e.target.value}`)}
          value={endDate}
        />
      </div>
      <Button className={classes.button} inputType={EInputTypes.submit}>
        Search
      </Button>
    </form>
  );
};

export default AgencySearch;
