import * as urlHelper from './../../components/DataParent/data/UrlQueries';
import { PswTypes } from './pswTypes';

const urlQueryParameter = urlHelper.parseUrlParameter();
export const initState: IPSWState = {
  loading: true,
  updateInProgress: false,
  error: false,
  refreshRate: 0,
  delayTimer: setInterval(function () {}, 0),
  showData: [],
  productData: [],
  kpiArray: {
    kpiTable: [],
    overPlan: [],
    underPlan: [],
    inPlan: [],
    overPlanMainChannel: [],
    underPlanMainChannel: [],
    inPlanMainChannel: [],
    onAirMinutes: 0,
    onAirMinutesOverall: 0,
  },
  urlQuery: urlQueryParameter,
  overPlan: [],
  underPlan: [],
  inPlan: [],
};

const pswReducer = (state = initState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case PswTypes.REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case PswTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PswTypes.SET_ERROR:
      return {
        ...state,
        loading: false,
        updateInProgress: false,
        error: true,
      };
    case PswTypes.SET_PROGRESS:
      return {
        ...state,
        updateInProgress: action.payload,
      };
    case PswTypes.SET_REFRESH_DELAY:
      return {
        ...state,
        ...[action.payload]
      };
    case PswTypes.SET_REFRESH_RATE:
      return {
        ...state,
        refreshRate: action.payload
      };
    default:
      return state;
  }
};
export { pswReducer };
