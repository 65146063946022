import classes from './DateRangeSearch.module.scss';
import { Button, EInputTypes } from '@hse24/shared-components';

interface DateRangeSearchProps {
  startDate: string;
  endDate: string;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  isLoading: boolean;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const DateRangeSearch = ({ startDate, endDate, setStartDate, setEndDate, isLoading, handleSubmit }: DateRangeSearchProps) => {

  return (
    <form onSubmit={handleSubmit} className={classes.search_container}>
      <div className={classes.date}>
        <h4>Start Date :</h4>
        <input
          className={classes.date_picker}
          type="date"
          onChange={e => setStartDate(`${e.target.value}`)}
          value={startDate}
        />
      </div>

      <div className={classes.date}>
        <h4>End Date :</h4>
        <input
          className={classes.date_picker}
          type="date"
          onChange={e => setEndDate(`${e.target.value}`)}
          value={endDate}
        />
      </div>
      <Button className={classes.button} inputType={EInputTypes.submit} disabled={isLoading}>
        Search
      </Button>
    </form>
  );
};

export default DateRangeSearch;
