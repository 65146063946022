import React, { useState } from 'react';
import { Segment, Button, SemanticSIZES } from 'semantic-ui-react';
import * as formatHelper from '../data/NumberStringFormat';
import * as changeHelper from './../data/onChange';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootTypes';
import {
  onErrorAction,
  requestSuccessAction,
  updateProgressAction,
} from '../../../redux/psw/pswActions';
import { pswRequest } from '../service/PSWAPIs';

interface IProps {
  dateString: string;
  dateStringToday: string;
}
export default function SegmentControlBtn(props: IProps) {
  const [state, setState] = useState({ dateString: props.dateString, dayDiff: 0 });
  const initState = useSelector((state: RootState) => state.psw);
  const dispatch = useDispatch();
  const onDateChange = async (dateDiff: number) => {
    if (!initState.updateInProgress) {
      const changedUrlQuery = changeHelper.onDateChange(dateDiff, initState.urlQuery);
      dispatch(updateProgressAction(true));
      const dataState = await pswRequest({ ...initState, urlQuery: changedUrlQuery });
      if (dataState) {
        return dispatch(requestSuccessAction({ ...dataState }));
      }
      return dispatch(onErrorAction());
    }
  };
  const onItemChange = (dayDiff: number) => {
    const newDateString = formatHelper.calcDayMonthStringFromUrlQuery(dayDiff, initState.urlQuery);
    if ((newDateString !== props.dateString || initState.error) && !initState.updateInProgress) {
      setState({
        dateString: newDateString,
        dayDiff: dayDiff,
      });
      onDateChange(dayDiff);
    }
  };
  var buttons = [];
  for (var i = 0; i < 6; i++) {
    var dayDiffLoading = state.dateString !== props.dateString && i === state.dayDiff;
    if (i === 0) {
      buttons.push(
        <DateBtn
          key={'dbtn' + i}
          loading={dayDiffLoading}
          dateString={props.dateStringToday}
          onItemChange={onItemChange}
          dayDiff={i}
        />
      );
    } else {
      buttons.push(
        <DateBtn
          key={'dbtn' + i}
          loading={dayDiffLoading}
          onItemChange={onItemChange}
          dayDiff={i}
        />
      );
    }
  }
  return (
    <Segment inverted textAlign="center">
      {buttons}
    </Segment>
  );
}
type Props = {
  dayDiff: number;
  loading: boolean;
  onItemChange: (dayDiff: number) => void;
  dateString?: string;
};
function DateBtn(props: Props) {
  var btnSize = 'small';
  if (window.innerWidth < 350) {
    btnSize = 'mini';
  }
  const _onClick = () => {
    if (!props.loading) {
      props.onItemChange(props.dayDiff);
    }
  };
  return (
    <span>
      {props.dayDiff === 0 ? (
        <Button
          key={'dateBtn' + props.dayDiff}
          inverted
          circular
          size={btnSize as SemanticSIZES}
          loading={props.loading}
          onClick={_onClick}
          fluid
          style={{ marginBottom: '0.75em' }}
        >
          Heute {props.dateString}
        </Button>
      ) : (
        <Button
          key={'dateBtn' + props.dayDiff}
          inverted
          circular
          size={btnSize as SemanticSIZES}
          loading={props.loading}
          onClick={_onClick}
        >
          -{props.dayDiff}T
        </Button>
      )}
    </span>
  );
}
