import { Agency } from '../../types/agency';

export interface AgenciesState {
  agencies: Agency;
  error: boolean;
  loading: boolean;
  currentAgency: string;
}

export enum AgenciesActionTypes {
  FETCH_AGENCIES_DATA = 'agencies/FETCH_AGENCIES_DATA',
  FETCH_AGENCIES_FAILED = 'agencies/FETCH_AGENCIES_FAILED',
  FETCH_AGENCIES_LOADING = 'agencies/FETCH_AGENCIES_LOADING',
  FETCH_AGENCIES_SUCCEEDED = 'agencies/FETCH_AGENCIES_SUCCEEDED',
  CURRENT_AGENCY = 'agencies/CURRENT_AGENCY',
}
