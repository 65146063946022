import { Presenters } from './presenters';

export enum Channel {
  HSE24 = 'HSE24',
  HSE24EXTRA = 'HSE24EXTRA',
  HSE24TREND = 'HSE24TREND',
}

export type TvShowsResponse = {
  tv_shows: TvShow[];
};

export type TvShow = {
  id: number;
  date_time: string;
  channel: Channel;
  title: string;
  products?: {
    base_product_no: string;
    type: string; // 'OTHER' | 'HINT' | 'RESERVE'
    position?: number;
  }[];
  is_on_air: boolean;
  presenters: Presenters[] | [];
  displayed_products_total?: number;
};

export type ShowsRevenue = {
  day_str: string;
  show: ShowExtraInfo[];
};

export type ShowExtraInfo = {
  show_hour: number;
  show_title: string;
  wrd_code: string;
  fs_hour_mmfc: number;
  fs_hour_act: number;
  colour_r: number;
  colour_g: number;
  colour_b: number;
  day_str: string;
  new_cust_hour: number;
};

export enum ChannelCode {
  ATV = 'ATV',
  DIG = 'DIG',
  TRE = 'TRE',
}

export type ChannelInfo = {
  nkd: number;
  fsp: number;
  fsi: number;
  sha_code: ChannelCode;
};
export type ChannelsDailyRevenue = {
  day_str: string;
  day_view_responses: ChannelInfo[];
};
