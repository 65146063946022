import HseIcon from '../../assets/HseIcon';
import classes from './ChannelCard.module.scss';
import cx from 'classnames';
import { Channel } from '../../types/tvShow';
import Config from "../../config/config";

interface ChannelCardProps {
  channel: Channel;
  current: boolean;
}

const ChannelCard = ({ channel, current }: ChannelCardProps) => {
  return (
    <a
      className={cx(classes.channel_card, {
        [classes.current_channel]: current,
      })}
      href={`${Config.rootRoute}/show-reporting/?channel=${channel}`}
    >
      <HseIcon />
      <p>{channel}</p>
    </a>
  );
};

export default ChannelCard;
