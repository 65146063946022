import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../routes/routes';
import { useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import { RootState } from "../redux/rootTypes";
import { RouterState } from "connected-react-router";

function App() {
  const token = useSelector((state: RootState) => state.app.token);
  const router = useSelector((state: RootState) => state.router);

  return (
    <Switch>
      {Object.keys(routes).map(route => determineRoute(route, token, router!))}
    </Switch>
  );
}

function determineRoute(route: string, token: string | null, router: RouterState) {
  const selectedRoute = routes[route];

  if (selectedRoute.protected) {
    return (
      <ProtectedRoute
        key={selectedRoute.path}
        exact={selectedRoute.exact}
        path={selectedRoute.path}
        location={router!.location}
        authenticated={!!token}
        component={selectedRoute.component}
      />
    );
  }

  return <Route {...selectedRoute} key={selectedRoute.path}/>;
}

export default App;
