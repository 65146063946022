import classes from './AgencyCard.module.scss';
import cx from 'classnames';

interface Props {
  name: string;
  colored: boolean;
}
const AgencyCard = ({ name, colored }: Props) => {
  return (
    <div className={cx(classes.card_container, { [classes.selected_card]: colored })}>
      <h2>{name}</h2>
    </div>
  );
};

export default AgencyCard;
