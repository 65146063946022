import { ChannelCode } from '../types/tvShow';

export const parseChannel = (channel: string) => {
  switch (channel) {
    case 'HSE24TREND':
      return ChannelCode.TRE;
    case 'HSE24EXTRA':
      return ChannelCode.DIG;
    case 'HSE24':
      return ChannelCode.ATV;
    default:
      return ChannelCode.DIG;
  }
};
