import { ProductActionTypes } from './productTypes';
import { Action } from 'redux';
import {
  Dimensions,
  Product,
  ProductOnAir,
  SingleProductState,
  Variant,
} from '../../types/product';
import { Stock } from '../../types/stock';
import { TvShow } from '../../types/tvShow';
import { VariantsSales } from '../../types/salesCount';

export class FetchProductsByShowId implements Action {
  type = ProductActionTypes.FETCH_PRODUCTS_BY_SHOWID;
  payload: {
    showId: string;
  };
  constructor(showId: string) {
    this.payload = {
      showId,
    };
  }
}

export class FetchProductsByShowIdSucceeded implements Action {
  payload: {
    productsByShowIdData: TvShow;
  };
  type = ProductActionTypes.FETCH_PRODUCTS_BY_SHOWID_SUCCEEDED;

  constructor(productsByShowIdData: TvShow) {
    this.payload = {
      productsByShowIdData,
    };
  }
}

export class FetchProductsByShowIdFailed implements Action {
  type = ProductActionTypes.FETCH_PRODUCTS_BY_SHOWID_FAILED;
}

export class FetchOnAirProduct implements Action {
  type = ProductActionTypes.FETCH_PRODUCT_ON_AIR;
  payload: {
    channel: string;
    dateTime: string;
  };
  constructor(channel: string, dateTime: string) {
    this.payload = {
      channel,
      dateTime,
    };
  }
}

export class FetchOnAirProductSucceeded implements Action {
  type = ProductActionTypes.FETCH_PRODUCT_ON_AIR_SUCCEEDED;
  payload: {
    productOnAir: ProductOnAir;
  };
  constructor(productOnAir: ProductOnAir) {
    this.payload = {
      productOnAir,
    };
  }
}

export class FetchOnAirProductFailed implements Action {
  type = ProductActionTypes.FETCH_PRODUCT_ON_AIR_FAILED;
}

export class FetchProductByNo implements Action {
  type = ProductActionTypes.FETCH_PRODUCT_BY_NO;
  payload: {
    productsBaseNo: string;
  };
  constructor(productsBaseNo: string) {
    this.payload = {
      productsBaseNo,
    };
  }
}

export class FetchProductByNoSucceeded implements Action {
  payload: {
    product: { baseProductNo: string; error?: boolean; product?: Product }[];
  };
  type = ProductActionTypes.FETCH_PRODUCT_BY_NO_SUCCEEDED;

  constructor(product: SingleProductState[]) {
    this.payload = {
      product,
    };
  }
}

export class FetchProductByNoFailed implements Action {
  type = ProductActionTypes.FETCH_PRODUCT_BY_NO_FAILED;
  payload: {
    productsBaseNo: string;
  };
  constructor(productsBaseNo: string) {
    this.payload = {
      productsBaseNo,
    };
  }
}

export class CurrentVariantsSet implements Action {
  type = ProductActionTypes.CURRENT_VARIANTS_SET;
  payload: {
    productsBaseNo: string;
  };
  constructor(productsBaseNo: string) {
    this.payload = {
      productsBaseNo,
    };
  }
}

export class CurrentVariants implements Action {
  type = ProductActionTypes.CURRENT_VARIANTS;
  payload: {
    variants: Variant[];
  };
  constructor(variants: Variant[]) {
    this.payload = {
      variants,
    };
  }
}

export class CurrentProductDimension implements Action {
  type = ProductActionTypes.CURRENT_PRODUCT_DIMENSIONS;
  payload: {
    dimensions: Dimensions[];
  };
  constructor(dimensions: Dimensions[]) {
    this.payload = {
      dimensions,
    };
  }
}

export class CurrentProductStock implements Action {
  type = ProductActionTypes.CURRENT_PRODUCT_STOCK;
  payload: {
    stock: Stock[];
  };
  constructor(stock: Stock[]) {
    this.payload = {
      stock,
    };
  }
}
export class ClearVariants implements Action {
  type = ProductActionTypes.CLEAR_VARIANT;
}

export class CurrentVariantsSales implements Action {
  type = ProductActionTypes.CURRENT_VARIANTS_SALES;
  payload: {
    currentVariantsSales: VariantsSales[];
  };
  constructor(currentVariantsSales: VariantsSales[]) {
    this.payload = {
      currentVariantsSales,
    };
  }
}
