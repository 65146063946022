// @ts-nocheck
// TODO: We need to fix the warnings in this file.
import queryString from 'query-string';
export function addStateToUrl(urlQuery: URLQuery) {
  //Browsers currently ignore second argument. pushState does not invoke refresh
  window.history.pushState(
    `${window.location.pathname}`,
    '',
      `${window.location.pathname}?${queryString.stringify(urlQuery)}`
  );
}
export function getUrlQueryString(urlQuery: URLQuery) {
  return queryString.stringify(urlQuery);
}
export function adjustUrlQueryForShowGroup(urlQuery: URLQuery) {
  if (urlQuery.group === 'SHOW') {
    urlQuery.showProducts = true;
  }
  return urlQuery;
}
export function checkDateIsToday(urlQuery: URLQuery) {
  const tempDate = new Date();
  if (
    tempDate.getFullYear() === Number(urlQuery.dateYear)
    && tempDate.getMonth() + 1 === Number(urlQuery.dateMonth)
    && tempDate.getDate() === Number(urlQuery.dateDay)
  ) {
    return true;
  } else {
    return false;
  }
}
export function parseUrlParameter(): URLQuery {
  const urlSearchParameter = queryString.parse(window.location.search);
  const todayDate = new Date();

  //ToDo Validity check of given parameters
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'dateDay')) {
    urlSearchParameter['dateDay'] = todayDate.getDate();
  }
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'dateMonth')) {
    urlSearchParameter['dateMonth'] = todayDate.getMonth() + 1;
  }
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'dateYear')) {
    urlSearchParameter['dateYear'] = todayDate.getFullYear();
  }
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'channel')) {
    urlSearchParameter['channel'] = 'ATV';
  } else {
    urlSearchParameter.channel = urlSearchParameter.channel.toUpperCase();
  }
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'group')) {
    urlSearchParameter['group'] = 'ADT';
  } else {
    urlSearchParameter.channel = urlSearchParameter.channel.toUpperCase();
  }
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'showHour')) {
    urlSearchParameter['showHour'] = todayDate.getHours();
  }
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'productView')) {
    urlSearchParameter['productView'] = false;
  } else {
    urlSearchParameter.productView = urlSearchParameter.productView === 'true';
  }
  if (!Object.prototype.hasOwnProperty.call(urlSearchParameter, 'showProducts')) {
    urlSearchParameter['showProducts'] = false;
  } else {
    urlSearchParameter.showProducts = urlSearchParameter.showProducts === 'true';
  }

  //Help out in case somebody messes with query parameters
  if (urlSearchParameter.showProducts) {
    urlSearchParameter.productView = true;
    if (urlSearchParameter.group === 'SHOW') {
      urlSearchParameter.showProducts = true;
    }
  }

  return urlSearchParameter;
}
