import { getEnvironment } from '../utils/environment';
import { Environment } from '../types/environment';

interface Configuration {
  env: Environment;
  rootRoute: string;
  baseUrl: string;
  tvShowServicePath: string;
  productPath: string;
  saleCountPath: string;
  stockPath: string;
  reportingService?: string;
  productOnAirPath: string;
  assetsUrl: string;
  cognito: {
    url: string;
    clientId: string;
    redirectBaseUrl: string;
  },
  mobileProducerSoftwareUrl: string;
  mobileProducerSoftwareBasicAuth: {
    username: string;
    password: string;
  };
}

const commonConfig = {
  rootRoute: '/dpl/mpsv',
  tvShowServicePath: '/dpl/tv-shows',
  productPath: '/dpl/pds/products',
  stockPath: '/dpl/pds/stock',
  saleCountPath: '/sales-count',
  productOnAirPath: '/dpl/tv-shows/productonair',
  assetsUrl: 'https://pic.hse24-dach.net/media/de/products',
  mobileProducerSoftwareUrl: 'https://www.hse.de/dpl/mps',
  mobileProducerSoftwareBasicAuth: {
    username: 'mpsw-service',
    password: 'Z$m&4Xx%Es3p8JXO',
  },
};

const development: Configuration = {
  ...commonConfig,
  env: Environment.Development,
  baseUrl: 'https://development.hse.de',
  cognito: {
    url: "https://mobile-producer-software-development.auth.eu-central-1.amazoncognito.com",
    clientId: "3h8mpkp791r5kfsa9v302t9jkk",
    redirectBaseUrl: "https://development.hse.de/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24-development.cloud',
};

const staging: Configuration = {
  ...commonConfig,
  env: Environment.Staging,
  baseUrl: 'https://qas.hse.de',
  cognito: {
    url: "https://mobile-producer-software-staging.auth.eu-central-1.amazoncognito.com",
    clientId: "7k6ltom41bod5ufsceks05sm64",
    redirectBaseUrl: "https://qas.hse.de/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24-staging.cloud',
};

const production: Configuration = {
  ...commonConfig,
  env: Environment.Production,
  baseUrl: 'https://www.hse.de',
  cognito: {
    url: "https://mobile-producer-software-production.auth.eu-central-1.amazoncognito.com",
    clientId: "92c1tlhuqbr5rpdb6j3n0e4c9",
    redirectBaseUrl: "https://www.hse.de/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24.cloud',
};

const local: Configuration = {
  ...commonConfig,
  env: Environment.Local,
  baseUrl: 'https://development.hse.de',
  cognito: {
    url: "https://mobile-producer-software-development.auth.eu-central-1.amazoncognito.com",
    clientId: "3h8mpkp791r5kfsa9v302t9jkk",
    redirectBaseUrl: "http://localhost:3000/dpl/mpsv"
  },
  reportingService: 'https://reporting-service.checkout.hse24-development.cloud',
};

const getConfiguration = (): Configuration => {
  const env = getEnvironment();

  switch (env) {
    case Environment.Local:
      return local;
    case Environment.Development:
      return development;
    case Environment.Staging:
      return staging;
    case Environment.Production:
      return production;
    default:
      throw Error(`Unknown environment: ${env}`);
  }
};

const Config = new Proxy<Configuration>(getConfiguration(), {
  get: (target: object, propertyKey: PropertyKey, receiver?: any) => {
    return Reflect.get(getConfiguration(), propertyKey, receiver);
  },
});

export default Config;
