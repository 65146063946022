import { ShowsState, ShowsActionTypes } from './showsTypes';

export const initialState: ShowsState = {
  showsList: [],
  error: false,
  loading: true,
  shows_revenue: {
    day_str: '',
    show: [],
  },
  shows_revenue_loading: true,
  shows_revenue_err: false,
  channels_daily_revenue: {
    day_str: '',
    day_view_responses: [],
  },
  channels_daily_revenue_loading: true,
  channels_daily_revenue_error: false,
};

const showsReducer = (
  state = initialState,
  action: { type: string; payload?: any }
): ShowsState => {
  switch (action.type) {
    case ShowsActionTypes.FETCH_SHOWS_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        showsList: [...action.payload.data['tv_shows']],
      };
    case ShowsActionTypes.FETCH_SHOWS_DATA_FAILED:
      return { ...state, error: true, loading: false };

    case ShowsActionTypes.FETCH_SHOWS_REVENUE_SUCCEEDED:
      return {
        ...state,
        shows_revenue_err: false,
        shows_revenue_loading: false,
        shows_revenue: action.payload.data['shows'],
      };

    case ShowsActionTypes.FETCH_SHOWS_REVENUE_FAILED:
      return { ...state, shows_revenue_err: true, shows_revenue_loading: false };

    case ShowsActionTypes.FETCH_CHANNELS_DAILY_REVENUE_SUCCEEDED:
      return {
        ...state,
        channels_daily_revenue_error: false,
        channels_daily_revenue_loading: false,
        channels_daily_revenue: action.payload.data['day_views_response'],
      };

    case ShowsActionTypes.FETCH_CHANNELS_DAILY_REVENUE_FAILED:
      return {
        ...state,
        channels_daily_revenue_error: true,
        channels_daily_revenue_loading: false,
      };
    default:
      return state;
  }
};

export default showsReducer;
