import React from 'react';
import { Card, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import * as formatHelper from './data/NumberStringFormat';
import { getLeadingZero } from './data/NumberStringFormat';
import { GetControlBtnWithProps } from './visual/SegmentsWithProps';
import ProductCard from './visual/ProductCard';
import * as changeHelper from './data/onChange';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootTypes';
import {
  onErrorAction,
  requestSuccessAction,
  updateProgressAction,
} from '../../redux/psw/pswActions';
import { pswRequest } from './service/PSWAPIs';
import SegmentChannelBtn from './visual/SegmentChannelBtn';
import SegmentGroupBtn from './visual/SegmentGroupBtn';

enum TypeName {
  show = 'Produkte der Stunde',
  jwd = 'Joker der Woche',
  mi = 'Moneyitems',
  def = 'Angebote des Tages',
}

export default function ProductsGrid() {
  const state = useSelector((state: RootState) => state.psw);
  const dispatch = useDispatch();

  const onShowHourChange = async (event: any) => {
    const newShowhour = event.target.value;
    if (!state.updateInProgress) {
      const changedUrlQuery: URLQuery = changeHelper.onShowHourChange(newShowhour, state.urlQuery);
      dispatch(updateProgressAction(true));
      const dataState = await pswRequest({ ...state, urlQuery: changedUrlQuery });
      if (dataState) {
        return dispatch(requestSuccessAction(dataState));
      }
      return dispatch(onErrorAction());
    }
  };

  const dayMonthString = formatHelper.getDayMonthString(
    new Date(state.urlQuery.dateYear, state.urlQuery.dateMonth - 1, state.urlQuery.dateDay)
  );
  const dayMonthStringToday = formatHelper.getDayMonthString(new Date());

  let itemsPerRow = 2;
  if (window.innerWidth > 500) {
    itemsPerRow = 3;
  }
  if (window.innerWidth > 1000) {
    itemsPerRow = 6;
  }
  if (state.productData.length < 2) {
    itemsPerRow = 1;
  }
  let fullTypeName: string;
  switch (state.urlQuery.group) {
    case 'SHOW':
      fullTypeName = TypeName.show;
      break;
    case 'JDW':
      fullTypeName = TypeName.jwd;
      break;
    case 'MI':
      fullTypeName = TypeName.mi;
      break;
    default:
      fullTypeName = TypeName.def;
      break;
  }
  const showHourOptions = Array.from(Array(24).keys()).map(i => (
    <option key={i} value={'' + i}>
      um {getLeadingZero(i)} Uhr
    </option>
  ));
  let nks = 0;
  if (state.productData.length > 0) {
    nks = state.productData[0].nks;
  }

  return (
    <Grid centered style={{ paddingTop: '3.5rem' }}>
      <Grid.Row className="noPadding">
        <Segment inverted>
          <Header as="h4" inverted textAlign="center">
            Produktübersicht {dayMonthString}
          </Header>
          <SegmentChannelBtn />

          <GetControlBtnWithProps
            dayMonthStringToday={dayMonthStringToday}
            dayMonthString={dayMonthString}
          />

          <SegmentGroupBtn />
          {state.urlQuery.showProducts && (
            <Segment inverted style={{ paddingTop: '0', paddingBottom: '0' }}>
              <select
                color="orange"
                defaultValue={state.urlQuery.showHour}
                onChange={onShowHourChange}
              >
                {showHourOptions}
              </select>
            </Segment>
          )}
          <Header as="h5" dividing inverted textAlign="center">
            <div>
              <Icon name="gift" />
              {state.productData.length} {fullTypeName}
              {nks > 0 && (
                <div>
                  <Icon name="user plus" />
                  {nks} Neukunden
                </div>
              )}
            </div>
          </Header>
        </Segment>
      </Grid.Row>
      <Grid.Row className="noPadding">
        <Segment inverted style={{ paddingLeft: '2em', paddingRight: '2em' }}>
          <Card.Group
            // @ts-expect-error
            itemsPerRow={itemsPerRow}
          >
            {state.productData.map((e, i) => {
              return <ProductCard key={i} productDataEntry={e} />;
            })}
          </Card.Group>
        </Segment>
      </Grid.Row>
    </Grid>
  );
}
