
export const getLeadingZero = (n: number) => {
  if (n >= 0 && n < 10) {
    return `0${n}`;
  }
  return `${n}`;
};
export const addDotToNumber = (n: number) => {
  let num: string = '';
  if (n === undefined) {
    num = '0';
  } else if (!isNaN(n)) {
    num += `${n}`;
  }
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
export function getDayMonthString(tempDate: Date) {
  return getLeadingZero(tempDate.getDate()) + '.' + getLeadingZero(tempDate.getMonth() + 1) + '.';
}
export const returnDateStringForESB = (day: number, month: number, year: number) => {
  return getLeadingZero(day) + '-' + getLeadingZero(month) + '-' + year;
};
export const calcDayMonthStringFromUrlQuery = (dayDiff: number, urlQuery: any) => {
  var tempDate;
  if (dayDiff === 0) {
    tempDate = new Date();
  } else {
    tempDate = new Date(urlQuery.dateYear, urlQuery.dateMonth - 1, urlQuery.dateDay);
    tempDate.setDate(tempDate.getDate() - dayDiff);
  }
  return getDayMonthString(tempDate);
};
export const secondsToMinutesString = (seconds: number) => {
  return new Date(1000 * seconds).toISOString().substr(14, 5);
};
export const minutesToHoursString = (minutes: number) => {
  return new Date(60000 * minutes).toISOString().substr(11, 5);
};
