import React, { useEffect } from 'react';
import classes from './Agency.module.scss';
import AgencySearch from './AgencySearch/AgencySearch';
import AgencySideBar from './AgencySideBar/AgencySideBar';
import AgencyTable from './AgencyTable/AgencyTable';
import { useDispatch, useSelector } from 'react-redux';
import { FetchAgencies } from '../../redux/agency/agencyActions';
import dayjs from 'dayjs';
import cx from 'classnames';
import { RootState } from '../../redux/rootTypes';
import { DAY_FORMAT_ONLY } from '../../constants/constants';
import Loading from '../UI/Loading';
import Error from '../UI/Error';
const Agency = () => {
  const dispatch = useDispatch();
  const monthStart = dayjs().startOf('month').format(DAY_FORMAT_ONLY);
  const today = dayjs().format(DAY_FORMAT_ONLY);
  const { loading, error } = useSelector((state: RootState) => state.agency);

  useEffect(() => {
    dispatch(new FetchAgencies(monthStart, today));
  }, [dispatch, today, monthStart]);

  return (
    <div className={cx(classes.container, { [classes.loading_error]: loading || error })}>
      {!loading && !error && <AgencySideBar />}
      {loading && <Loading className={classes.loading} />}
      {error && <Error className={classes.error} />}
      <div
        className={cx(classes.table_wrapper, { [classes.table_loading_error]: loading || error })}
      >
        <AgencySearch />
        {!loading && !error && <AgencyTable />}
      </div>
    </div>
  );
};

export default Agency;
