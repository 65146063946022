import dayjs from 'dayjs';
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../api/api';
import { DEFAULT_DATETIME_FORMAT } from '../../constants/constants';
import {
  FetchCrossSellsReport,
  FetchCrossSellsReportFailed,
  FetchCrossSellsReportSucceeded,
} from './crossSellsActions';
import { CrossSellsReportActionTypes } from './crossSellsTypes';

export function* fetchCrossSellsReport(action: FetchCrossSellsReport) {
  const { payload } = action;

  try {
    const { data } = yield call(
      API.getCrossSellsReport,
      dayjs(payload.startDate).startOf('day').format(DEFAULT_DATETIME_FORMAT),
      dayjs(payload.endDate).endOf('day').format(DEFAULT_DATETIME_FORMAT),
    );

    yield put(new FetchCrossSellsReportSucceeded(data));
  } catch (err) {
    yield put(new FetchCrossSellsReportFailed());
  }
}

export default function* fetchCrossSellsReportSagaWatcher() {
  yield takeLatest(CrossSellsReportActionTypes.FETCH_CROSS_SELLS_REPORT_DATA, fetchCrossSellsReport);
}
