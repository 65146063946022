import React from 'react';
import Agency from '../components/Agency/Agency';
import CrossSellsReport from '../components/CrossSellsReport/CrossSellsReport';
import ReklaOrdersReport from '../components/ReklaOrdersReport/ReklaOrdersReport';
import TvProgram from '../components/ShowList/TvProgram';
import ShowContainer from '../containers/ShowContainer';
import MobileApp from '../pages/MobileApp/MobileApp';
import OAuthSignIn from "../containers/OAuthSignIn";
import Config from "../config/config";

interface RouteDefinition {
  exact?: boolean;
  path: string;
  protected: boolean;
  component: React.ComponentType;
}

type Routes = Record<string, RouteDefinition>;

function createRoutePath(path: string): string {
  return Config.rootRoute.concat(path);
}

const routes: Routes = {
  Landing: {
    component: MobileApp,
    exact: true,
    protected: true,
    path: Config.rootRoute,
  },
  showDetail: {
    component: ShowContainer,
    exact: true,
    protected: true,
    path: createRoutePath('/show-reporting/:showId/'),
  },
  showList: {
    component: TvProgram,
    exact: true,
    protected: true,
    path: createRoutePath('/show-reporting'),
  },
  Agency: {
    component: Agency,
    exact: true,
    protected: true,
    path: createRoutePath('/agency-reporting'),
  },
  AgencyCrossSell: {
    component: CrossSellsReport,
    exact: true,
    protected: true,
    path: createRoutePath('/agency-reporting/cross-sells'),
  },
  AgencyReklaOrders: {
    component: ReklaOrdersReport,
    exact: true,
    protected: true,
    path: createRoutePath('/agency-reporting/rekla-orders'),
  },
  Oauth: {
    component: OAuthSignIn,
    exact: true,
    protected: false,
    path: createRoutePath('/oauth-sign-in'),
  }
};

export default routes;
