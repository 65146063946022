import { Action } from "redux";
import { AppActionTypes } from "./appTypes";
import { AuthorizationCodeResponse } from "../../types/cognito";

export class FetchToken implements Action {
  type = AppActionTypes.FETCH_TOKEN;
  payload: string;

  constructor(token: string) {
    this.payload = token;
  }
}

export class FetchTokenSuccess implements Action {
  payload: AuthorizationCodeResponse;
  type = AppActionTypes.FETCH_TOKEN_SUCCESS;

  constructor(tokenResponse: AuthorizationCodeResponse) {
    this.payload = tokenResponse;
  }
}

export class FetchTokenFailed implements Action {
  type = AppActionTypes.FETCH_TOKEN_FAILED;
}

export type AppActions =
  | FetchToken
  | FetchTokenSuccess
  | FetchTokenFailed;
