import { Action } from 'redux';
import { Agency } from '../../types/agency';
import { AgenciesActionTypes } from './agencyTypes';

export class FetchAgencies implements Action {
  type = AgenciesActionTypes.FETCH_AGENCIES_DATA;
  payload: {
    startDate: string;
    endDate: string;
  };
  constructor(startDate: string, endDate: string) {
    this.payload = {
      startDate,
      endDate,
    };
  }
}

export class FetchAgenciesSucceeded implements Action {
  type = AgenciesActionTypes.FETCH_AGENCIES_SUCCEEDED;

  payload: {
    agencies: Agency;
  };

  constructor(agencies: Agency) {
    this.payload = {
      agencies,
    };
  }
}

export class FetchAgenciesFailed implements Action {
  type = AgenciesActionTypes.FETCH_AGENCIES_FAILED;
}

export class FetchAgenciesLoading implements Action {
  type = AgenciesActionTypes.FETCH_AGENCIES_LOADING;
}

export class CurrentAgency implements Action {
  type = AgenciesActionTypes.CURRENT_AGENCY;

  payload: {
    currentAgency: string;
  };

  constructor(currentAgency: string) {
    this.payload = {
      currentAgency,
    };
  }
}
