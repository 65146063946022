import classes from './ProgramCard.module.scss';
import cx from 'classnames';
import dayjs from 'dayjs';
import { TvShow } from '../../types/tvShow';
import { Host, Role } from '../../types/product';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Config from "../../config/config";
interface ProgramCardProps {
  program: TvShow;
}

const ProgramCard = ({ program }: ProgramCardProps) => {
  const dispatch = useDispatch();
  if (!program) return null;

  return (
    <div className={classes.show_row}>
      <div className={cx(classes.hour, { [classes.live]: program.is_on_air })}>
        {dayjs(program.date_time).format('HH:mm')}
      </div>
      <div
        className={cx(classes.program_card, { [classes.live]: program.is_on_air })}
        onClick={() =>
          dispatch(
            push(
              `${Config.rootRoute}/show-reporting/${program.id}/?date_time=${program.date_time}&channel=${program.channel}`
            )
          )
        }
      >
        <p className={classes.products_live}>
          {program.displayed_products_total} Products {program.is_on_air && <span>Live now</span>}
        </p>
        <p>{program.title}</p>
        <p className={classes.host}>
          {program.presenters &&
            program.presenters.map(
              (host: Host, key: number) =>
                host.role === Role.HOS && (
                  <span key={key}>{`${host.first_name} ${host.last_name}`}</span>
                )
            )}
        </p>
      </div>
    </div>
  );
};
export default ProgramCard;
