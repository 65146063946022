import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Segment, SemanticCOLORS } from 'semantic-ui-react';
import {
  onErrorAction,
  requestSuccessAction,
  updateProgressAction,
} from '../../../redux/psw/pswActions';
import { RootState } from '../../../redux/rootTypes';
import * as changeHelper from './../data/onChange';
import { pswRequest } from '../service/PSWAPIs';

const channels = ['ATV', 'DIG', 'TRE'];

export default function SegmentChannelBtn() {
  const initState = useSelector((state: RootState) => state.psw);
  const dispatch = useDispatch();
  const [state, setState] = useState({ channel: initState.urlQuery.channel });

  const onChannelChange = async (newChannel: string) => {
    if (!initState.updateInProgress) {
      const changedUrlQuery = changeHelper.onChannelChange(newChannel, initState.urlQuery);

      dispatch(updateProgressAction(true));
      const dataState = await pswRequest({ ...initState, urlQuery: changedUrlQuery });
      if (dataState) {
        return dispatch(requestSuccessAction({ ...dataState }));
      }
      return dispatch(onErrorAction());
    }
  };

  const onItemChange = (newChannel: string) => {
    if (
      (newChannel !== initState.urlQuery.channel || initState.error) &&
      !initState.updateInProgress
    ) {
      setState({
        channel: newChannel,
      });
      onChannelChange(newChannel);
    }
  };

  return (
    <Segment inverted textAlign="center" className="noMargin">
      {channels.map((channel, index) => {
        const newChannelLoading =
          state.channel !== initState.urlQuery.channel && state.channel === channel;

        if (channel === initState.urlQuery.channel) {
          return (
            <ChannelBtn key={index} color="orange" channel={channel} onItemChange={onItemChange} />
          );
        } else {
          return (
            <ChannelBtn
              key={index}
              channel={channel}
              loading={newChannelLoading}
              onItemChange={onItemChange}
            />
          );
        }
      })}
    </Segment>
  );
}

function mapButtonName(channel: string) {
  switch (channel) {
    case 'ATV':
      return 'HSE';
    case 'TRE':
      return 'TRE';
    case 'DIG':
      return 'EXT';
  }
}

type Props = {
  channel: string;
  onItemChange: (newgroup: string) => void;
  group?: string;
  color?: string;
  loading?: boolean;
};

function ChannelBtn(props: Props) {
  function _onClick() {
    if (!props.loading) {
      props.onItemChange(props.channel);
    }
  }

  return (
    <Button
      key={'btn' + props.channel}
      inverted
      circular
      size="tiny"
      color={props.color as SemanticCOLORS}
      loading={props.loading}
      onClick={_onClick}
    >
      {mapButtonName(props.channel)}
    </Button>
  );
}
