import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { RootState } from '../../../redux/rootTypes';
import { addDotToNumber, minutesToHoursString } from '../data/NumberStringFormat';

interface IProps {
  dateIsToday: boolean;
}
export default function TableDayInfo(props: IProps) {
  const state = useSelector((state: RootState) => state.psw);
  const kpiTable = state.kpiArray.kpiTable;
  let kpiEntry: IDayViewEntry = kpiTable[0];
  for (let i = 0; i < kpiTable.length; i++) {
    if (kpiTable[i].channel === state.urlQuery.channel) {
      kpiEntry = kpiTable[i];
    }
  }
  let hourCellContent = '-';
  let hourCellPercentageContent = '-';
  if (state.kpiArray.onAirMinutes > 0) {
    hourCellContent = minutesToHoursString(state.kpiArray.onAirMinutes);
    hourCellPercentageContent = addDotToNumber(
      Math.round((state.kpiArray.onAirMinutes * 100) / state.kpiArray.onAirMinutesOverall)
    );
  }

  return (
    <div>
      <Table unstackable structured inverted>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3" textAlign="center">
              Floorsales
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>MMFC €</Table.HeaderCell>
            <Table.HeaderCell>Ist €</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Ist %</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{addDotToNumber(kpiEntry.fsPlan)}</Table.Cell>
            <Table.Cell>{addDotToNumber(kpiEntry.fsAct)}</Table.Cell>
            <Table.Cell textAlign="center">
              {addDotToNumber(Math.round((kpiEntry.fsAct / kpiEntry.fsPlan) * 100))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table unstackable structured inverted>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2">
              Neu-
              <br />
              kunden
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2" textAlign="center">
              Live Stunden
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Akt. Stunde</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">% des Tages</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{addDotToNumber(kpiEntry.nkd)}</Table.Cell>
            <Table.Cell textAlign="center">{props.dateIsToday ? hourCellContent : '-'}</Table.Cell>
            <Table.Cell textAlign="center">
              {props.dateIsToday ? hourCellPercentageContent : '-'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}
