import classes from './ShowInfo.module.scss';
import dayjs from 'dayjs';
import { Presenters } from '../../types/presenters';
import cx from 'classnames';
import { Host, Role } from '../../types/product';

interface Props {
  title: string;
  time: string;
  presenters: Presenters[];
  isOnAir: boolean;
}

const ShowInfo = ({ title, time, presenters, isOnAir }: Props) => {
  return (
    <div className={cx(classes.show_info_container, { [classes.inOnAir]: isOnAir })}>
      <div className={classes.show_info}>
        <h4>Title</h4>
        <span> {title}</span>
        <h4>Time</h4>
        <span>{`${dayjs(time).format('YYYY-MM-DD HH')}h`}</span>
        <h4>Presenters</h4>
        {presenters.map(
          (host: Host, key: number) =>
            host.role === Role.HOS && (
              <span key={key}>
                {host.first_name} {host.last_name}
              </span>
            )
        )}
      </div>
      {isOnAir && (
        <div className={classes.live}>
          <div className={classes.point} />
          <h4>live now</h4>
        </div>
      )}
    </div>
  );
};

export default ShowInfo;
