import React from 'react';
import useQueryParams from '../hooks/useQueryParams';
import { Channel } from '../types/tvShow';
import ShowDetails from '../components/ShowDetails/ShowDetails';
import { useParams } from 'react-router-dom';

export default function ShowContainer() {
  const params = useParams<{ showId: string }>();
  const queryParams = useQueryParams<{ date_time: string; channel: Channel }>();

  if (queryParams.date_time === undefined || queryParams.channel === undefined) {
    return <div>Please add "date_time" or "channel" query params</div>;
  }

  return (
    <ShowDetails
      showId={params.showId}
      channel={queryParams.channel}
      dateTime={queryParams.date_time}
    />
  );
}
