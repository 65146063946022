import { CrossSellsReport } from '../../types/crossSellsReport';

export interface CrossSellsReportState {
  report: CrossSellsReport;
  error: boolean; 
  loading: boolean;
}

export enum CrossSellsReportActionTypes {
  FETCH_CROSS_SELLS_REPORT_DATA = 'agencies/cross-sells-report/FETCH_DATA',
  FETCH_CROSS_SELLS_REPORT_FAILED = 'agencies/cross-sells-report/FETCH_FAILED',
  FETCH_CROSS_SELLS_REPORT_SUCCEEDED = 'agencies/cross-sells-report/FETCH_SUCCEEDED',
}
