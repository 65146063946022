import React from 'react';
import { Header, Icon, Segment, List, Progress } from 'semantic-ui-react';
import ListItemShow from './ListItemShow';

interface IProps {
  showMinute: number;
  showDataEntry?: IShowDataEntry;
  urlQuery: URLQuery;
}
export default function SegmentCurrentShow(props: IProps) {
  const showPercentage = (props.showMinute * 100) / 60;
  return (
    <Segment inverted className="noPaddingTopBottom">
      <Header as="h5" dividing inverted textAlign="center">
        <Icon name="calendar" />
        Aktuelle Show
      </Header>
      <List divided inverted relaxed style={{background: "white"}}>
        <ListItemShow showDataEntry={props.showDataEntry} urlQuery={props.urlQuery} />
      </List>
      <Progress percent={showPercentage} size="tiny" active />
    </Segment>
  );
}
