import axios from 'axios';
import {
  compareIDs,
  parseDayViewData,
  parseProductData,
  parseShowData,
  serviceCalls,
} from '../data/serviceCalls';
import * as urlHelper from '../data/UrlQueries';


const APIs = new serviceCalls();

const insertOnAirBool = async (state: IPSWState) => {
  if (urlHelper.checkDateIsToday(state.urlQuery)) {
    return await APIs.onAir(state.urlQuery)
      .then(res => {
        const data: IOnAirResponse = res.data;
        let onAirID = data.on_air_response.product_id;
        let onAirSince = data.on_air_response.on_air_start;
        let productData = state.productData;
        let changeOccured = false;
        for (let i = 0; i < productData.length; i++) {
          if (compareIDs(productData[i].pic, onAirID)) {
            productData[i].onAir = true;
            productData[i].onAirSince = onAirSince;
            changeOccured = true;
          }
        }
        if (changeOccured) {
          return {
            ...state,
            productData: productData,
          };
        }
        return state;
      })
      .catch(() => null);
  }
  return null;
};

export const pswRequest = async (state: IPSWState) => {
  if (state.urlQuery.productView) {
    const productData = await APIs.product(state.urlQuery)
      .then(productData => {
        return {
          ...state,
          loading: false,
          updateInProgress: false,
          error: false,
          urlQuery: state.urlQuery,
          productData: parseProductData({...productData.data, ...state.urlQuery}),
        } as IPSWState;
      })
      .catch(() => null);

    if (productData) {
      const onAirData = await insertOnAirBool(productData);
      if (onAirData) {
        return onAirData;
      } else {
        return productData
      }
    }
    return null;
  }
  const dataState: IPSWState | null = await axios
    .all([APIs.dayView(state.urlQuery), APIs.show(state.urlQuery)])
    .then(
      axios.spread(async (dayviewData, showData) => {
        const parsedDayViewData = parseDayViewData(dayviewData.data);
        const parsedShowData = parseShowData(showData.data);
        return {
          ...state,
          loading: false,
          updateInProgress: false,
          error: false,
          urlQuery: state.urlQuery,
          showData: parsedShowData?.showData,
          overPlan: parsedShowData?.overPlan,
          underPlan: parsedShowData?.underPlan,
          inPlan: parsedShowData?.inPlan,
          kpiArray: parsedDayViewData,
        };
      }),
    )
    .catch(() => null);
  return dataState;
};
