import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { RootState } from '../../redux/rootTypes';
import * as formatHelper from './data/NumberStringFormat';
import { GetControlBtnWithProps } from './visual/SegmentsWithProps';
import SegmentChannelBtn from './visual/SegmentChannelBtn';
import SegmentGroupBtn from './visual/SegmentGroupBtn';

export default function DayViewErrorGrid() {
  const state = useSelector((state: RootState) => state.psw);
  const dayMonthString = formatHelper.getDayMonthString(
    new Date(state.urlQuery.dateYear, state.urlQuery.dateMonth - 1, state.urlQuery.dateDay)
  );
  const dayMonthStringToday = formatHelper.getDayMonthString(new Date());

  return (
    <Grid stackable columns={1} style={{ paddingTop: '3.5rem' }}>
      <Grid.Column className="noPadding" verticalAlign="middle">
        <Segment inverted textAlign="center">
          <Icon.Group size="massive">
            <Icon name="database" color="orange" />
          </Icon.Group>
          <div style={{ paddingTop: '1rem' }}>
            Leider sind keine Daten <br /> für den gewählten Zeitraum verfügbar.
          </div>

          <Header as="h5" dividing inverted textAlign="center">
            <Icon name="settings" />
            Steuerung
          </Header>

          <SegmentChannelBtn />

          <GetControlBtnWithProps
            dayMonthStringToday={dayMonthStringToday}
            dayMonthString={dayMonthString}
          />

          {state.urlQuery.productView && <SegmentGroupBtn />}
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
