import { Action } from 'redux';
import { ProductsStock } from '../../types/stock';
import { StockActionType } from './stockTypes';

export class FetchProductsStock implements Action {
  type = StockActionType.FETCH_PRODUCTS_STOCK;
  payload: {
    productsBaseNo: string[];
  };
  constructor(productsBaseNo: string[]) {
    this.payload = {
      productsBaseNo,
    };
  }
}

export class FetchProductsStockSucceeded implements Action {
  payload: {
    productsStock: ProductsStock;
  };
  type = StockActionType.FETCH_PRODUCTS_STOCK_SUCCEEDED;

  constructor(productsStock: ProductsStock) {
    this.payload = {
      productsStock,
    };
  }
}

export class FetchProductsStockFailed implements Action {
  type = StockActionType.FETCH_PRODUCTS_STOCK_FAILED;
}
