import React from 'react';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';

import TableDayInfo from './visual/TableDayInfo';

import SegmentCurrentShow from './visual/SegmentCurrentShow';
import SegmentShowList from './visual/SegmentShowList';
import ChartThemed from './visual/ChartThemed';
import SegmentChartKPIWrapper from './visual/SegmentChartKPIWrapper';
import * as formatHelper from './data/NumberStringFormat';
import { GetControlBtnWithProps } from './visual/SegmentsWithProps';
import { checkDateIsToday } from './data/UrlQueries';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootTypes';
import SegmentChannelBtn from './visual/SegmentChannelBtn';

export default function DayViewGrid() {
  const state = useSelector((state: RootState) => state.psw);

  const checkTodayShow = () => {
    const tempDate = new Date();
    if (checkDateIsToday(state.urlQuery)) {
      const currentHour = tempDate.getHours();
      const currentMinute = tempDate.getMinutes();
      return (
        <SegmentCurrentShow
          showDataEntry={state.showData && state.showData[currentHour]}
          showMinute={currentMinute}
          urlQuery={state.urlQuery}
        />
      );
    }
  };
  const dayMonthString = formatHelper.getDayMonthString(
    new Date(state.urlQuery.dateYear, state.urlQuery.dateMonth - 1, state.urlQuery.dateDay)
  );
  const dayMonthStringToday = formatHelper.getDayMonthString(new Date());

  return (
    <Grid stackable columns={2} style={{ paddingTop: '3.5rem' }}>
      <Grid.Column className="noPadding">
        <Segment inverted>
          <Header as="h4" inverted textAlign="center">
            Kanalübersicht {dayMonthString}
          </Header>
          <SegmentChartKPIWrapper kpiArray={state.kpiArray} />
          <SegmentChannelBtn />
          <TableDayInfo dateIsToday={checkDateIsToday(state.urlQuery)} />
          <Header as="h5" dividing inverted textAlign="center">
            <Icon name="settings" />
            Steuerung
          </Header>
          <GetControlBtnWithProps
            dayMonthStringToday={dayMonthStringToday}
            dayMonthString={dayMonthString}
          />
          {checkTodayShow()}
        </Segment>
      </Grid.Column>
      <Grid.Column className="noPadding extraPaddingResponsive">
        <Segment inverted className="noPaddingTopBottom">
          <Header as="h5" dividing inverted textAlign="center">
            <Icon name="chart bar" />
            Tagesübersicht {dayMonthString}
          </Header>
          <ChartThemed
            overPlan={state.overPlan}
            underPlan={state.underPlan}
            inPlan={state.inPlan}
            hourChart={true}
          />
        </Segment>
      </Grid.Column>
      <Grid.Column className="noPadding">
        <SegmentShowList addClassName="noPaddingBottom" />
      </Grid.Column>
      <Grid.Column className="noPadding">
        <SegmentShowList addClassName="noPaddingTop" secondTable={true} />
      </Grid.Column>
    </Grid>
  );
}
