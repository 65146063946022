import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Label, Menu, MenuItemProps } from 'semantic-ui-react';
import {
  onErrorAction,
  onLoadingAction,
  refreshDelayAction,
  refreshRateAction,
  requestSuccessAction,
} from '../../../redux/psw/pswActions';
import { RootState } from '../../../redux/rootTypes';
import * as changeHelper from './../data/onChange';
import * as urlHelper from '../data/UrlQueries';
import { pswRequest } from '../service/PSWAPIs';
import Config from '../../../config/config';
import { Environment } from '../../../types/environment';

interface IState {
  activeItem: string;
  refreshText: string;
  showTopButton: boolean;
  delayScrollListenerTimer?: NodeJS.Timeout;
}
export default function MenuTop() {
  const initState = useSelector((state: RootState) => state.psw);
  const dispatch = useDispatch();

  const [state, setState] = useState<IState>({
    activeItem: '',
    refreshText: '',
    showTopButton: false,
  });
  useEffect(() => {
    createRefreshText(initState.refreshRate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function checkScrollPosition(e: any) {
    if (state.delayScrollListenerTimer !== undefined) {
      clearInterval(state.delayScrollListenerTimer);
    }
    var docHeight =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);
    setState({
      ...state,
      delayScrollListenerTimer: setTimeout(function () {
        let showTopButton;
        if (docHeight >= 250) {
          showTopButton = true;
        } else {
          showTopButton = false;
        }
        setState({
          ...state,
          showTopButton: showTopButton,
        });
      }, 300),
    });
  }

  function createRefreshText(newRefreshRate: number) {
    let newRefreshText = '';
    if (newRefreshRate === 0) {
      newRefreshText = 'aus';
    } else {
      newRefreshText = newRefreshRate + 'M';
    }
    setState({
      ...state,
      refreshText: newRefreshText,
    });
  }
  const onMenuChange = async (name: string) => {
    dispatch(onLoadingAction(true));
    let changedUrlQuery: URLQuery = initState.urlQuery;
    let productViewBool;
    switch (name) {
      case 'productView':
        productViewBool = true;
        urlHelper.adjustUrlQueryForShowGroup(changedUrlQuery);
        break;
      default:
        productViewBool = false;
        changedUrlQuery = changeHelper.onShowProductsChange(false, changedUrlQuery);
        break;
    }
    changedUrlQuery = changeHelper.onMenuChange(productViewBool, changedUrlQuery);
    const dataState = await pswRequest({ ...initState, urlQuery: changedUrlQuery });
    if (dataState) {
      return dispatch(requestSuccessAction({ ...dataState }));
    }
    return dispatch(onErrorAction());
  };
  const onRefreshChangeData = (newRefreshRate: number) => {
    clearInterval(initState.delayTimer);
    let refreshrateInMs = newRefreshRate * 1000 * 60;
    if (Config.env === Environment.Development) {
      refreshrateInMs /= 10;
    }
    if (newRefreshRate > 0) {
      dispatch(
        refreshDelayAction({
          refreshRate: newRefreshRate,
          delayTimer: setInterval(async () => {
            const dataState = await pswRequest(initState);
            if (dataState) {
              return dispatch(requestSuccessAction({ ...dataState }));
            }
            return dispatch(onErrorAction());
          }, refreshrateInMs),
        })
      );
    } else {
      dispatch(refreshRateAction(newRefreshRate));
    }
  };
  function onRefreshChange() {
    var refreshIntervals = [0, 1, 2, 3, 5];

    var newRefreshRate;
    //let the refresh interval stick to the above defined values
    if (initState.refreshRate === 5) {
      newRefreshRate = 0;
    } else {
      newRefreshRate = refreshIntervals[refreshIntervals.indexOf(initState.refreshRate) + 1];
    }
    onRefreshChangeData(newRefreshRate);
    createRefreshText(newRefreshRate);
  }
  function _onClick(_: any, { name }: MenuItemProps) {
    if (name) {
      setState({
        ...state,
        activeItem: name,
      });
      onMenuChange(name);
    }
  }
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  const { activeItem } = state;
  return (
    <Menu fixed="top" inverted size="small">
      <Menu.Item name="showView" active={activeItem === 'showView'} onClick={_onClick}>
        <Label circular color={'orange'} style={{ marginRight: '0.5em' }}>
          HSE
        </Label>
        MPSW
      </Menu.Item>

      <Menu.Item name="productView" active={activeItem === 'productView'} onClick={_onClick}>
        Produkte
      </Menu.Item>
      <RefreshMenuItem refreshText={state.refreshText} onRefreshChange={onRefreshChange} />
      {state.showTopButton && (
        <Menu.Item onClick={scrollToTop}>
          <Icon name="angle double up" />
        </Menu.Item>
      )}
    </Menu>
  );
}
function RefreshMenuItem({
  refreshText,
  onRefreshChange,
}: {
  refreshText: string;
  onRefreshChange: (refreshRate: number) => void;
}) {
  const initState = useSelector((state: RootState) => state.psw);
  let loading = false;
  if (initState.refreshRate > 0) {
    loading = true;
  }
  function _onClick() {
    onRefreshChange(initState.refreshRate);
  }
  return (
    <Menu.Item name="refresh" onClick={() => _onClick}>
      <Icon loading={loading} name="refresh" />
      {refreshText}
    </Menu.Item>
  );
}
