export interface AppState {
  readonly token: string | null;
  readonly refreshToken: string | null;
  readonly authError: boolean;
}

export enum AppActionTypes {
  FETCH_TOKEN = 'app/FETCH_TOKEN',
  FETCH_TOKEN_SUCCESS = 'app/FETCH_TOKEN_SUCCESS',
  FETCH_TOKEN_FAILED = 'app/FETCH_TOKEN_FAILED',
}
