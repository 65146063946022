import { Environment } from '../types/environment';

export function getEnvironment(): Environment {
  if (typeof window === 'undefined') {
    return Environment.Local;
  }

  switch (window.location.hostname) {
    case 'localhost':
      return Environment.Local;
    case 'mobile-producer-software-view.core.hse24-development.cloud':
    case 'development.hse.de':
      return Environment.Development;
    case 'mobile-producer-software-view.core.hse24-staging.cloud':
    case 'qas.hse.de':
      return Environment.Staging;
    case 'mobile-producer-software-view.core.hse24.cloud':
    case 'www.hse.de':
      return Environment.Production;
  }

  return Environment.Local;
}
