import classes from './Switcher.module.scss';
import { Channel, TvShow } from '../../types/tvShow';
import { EButtonSize, Select } from '@hse24/shared-components';
import { useEffect, useState } from 'react';
import { getShowsByChannel } from '../../utils/misc';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ClearVariants } from '../../redux/products/productsActions';
import Config from "../../config/config";

interface ShowHeaderProps {
  showList: TvShow[];
  channel: Channel;
  currentProgram: TvShow;
}

const ShowHeader = ({ showList, channel, currentProgram }: ShowHeaderProps) => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState<{ label: string; value: number }[]>([
    {
      label: '',
      value: 0,
    },
  ]);

  useEffect(() => {
    setOptions(getShowsByChannel(showList, channel));
  }, [showList, channel]);

  return (
    <div className={classes.select_container}>
      <h4>Select a show</h4>
      <Select
        options={options}
        className={classes.select_wrapper}
        selectTriggerSize={EButtonSize.SMALL}
        label={currentProgram?.title}
        onChange={e => {
          const currentProgram = showList.find((show: TvShow) => `${show.id}` === e.value);
          dispatch(new ClearVariants());
          currentProgram &&
            dispatch(
              push(
                `${Config.rootRoute}/show-reporting/${currentProgram.id}/?date_time=${currentProgram.date_time}&channel=${currentProgram.channel}`
              )
            );
        }}
      />
    </div>
  );
};

export default ShowHeader;
