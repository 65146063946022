import { PswTypes } from "./pswTypes";

export const requestSuccessAction = (payload: IPSWState) => ({
  type: PswTypes.REQUEST_SUCCESS,
  payload,
});

export const onLoadingAction = (payload: boolean) => ({
  type: PswTypes.SET_LOADING,
  payload,
});

export const onErrorAction = () => ({
  type: PswTypes.SET_ERROR,
});

export const updateProgressAction = (payload: boolean) => ({
  type: PswTypes.SET_PROGRESS,
  payload,
});

export const refreshDelayAction = (payload: IRefreshDelay) => ({
  type: PswTypes.SET_REFRESH_DELAY,
  payload,
});

export const refreshRateAction = (payload: number) => ({
  type: PswTypes.SET_REFRESH_RATE,
  payload,
});
