import React, { useEffect } from 'react';
import 'semantic-ui-css/semantic.css';
import MenuTop from './visual/MenuTop';
import { Dimmer, Loader } from 'semantic-ui-react';
import * as urlHelper from './data/UrlQueries';
import DayViewErrorGrid from './DayViewErrorGrid';
import DayViewGrid from './DayViewGrid';
import ProductsGrid from './ProductsGrid';
import { RootState } from '../../redux/rootTypes';
import { useDispatch, useSelector } from 'react-redux';
import { pswRequest } from './service/PSWAPIs';
import { requestSuccessAction, onErrorAction } from '../../redux/psw/pswActions';

export default function DataParentComponent() {
  const state = useSelector((state: RootState) => state.psw);
  const dispatch = useDispatch();

  const init = async () => {
    const dataState = await pswRequest(state);
    if (dataState) {
      return dispatch(requestSuccessAction(dataState));
    }
    return dispatch(onErrorAction());
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  urlHelper.addStateToUrl(state.urlQuery);
  return (
    <div>
      <MenuTop />
      {state.loading && (
        <Dimmer active={state.loading} page>
          <Loader>Lade...</Loader>
        </Dimmer>
      )}
      {!state.error && !state.loading ? <MainGrid /> : <DayViewErrorGrid />}
    </div>
  );
}

const MainGrid = () => {
  const state = useSelector((state: RootState) => state.psw);
  return <div>{state.urlQuery.productView ? <ProductsGrid /> : <DayViewGrid />}</div>;
};
