import { ReklaOrdersReport } from '../../types/reklaOrdersReport';

export interface ReklaOrdersReportState {
  report: ReklaOrdersReport;
  error: boolean;
  loading: boolean;
}

export enum ReklaOrdersReportActionTypes {
  FETCH_REKLA_ORDERS_REPORT_DATA = 'agencies/rekla-orders-report/FETCH_DATA',
  FETCH_REKLA_ORDERS_REPORT_FAILED = 'agencies/rekla-orders-report/FETCH_FAILED',
  FETCH_REKLA_ORDERS_REPORT_SUCCEEDED = 'agencies/rekla-orders-report/FETCH_SUCCEEDED',
}
