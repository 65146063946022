import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAgencyAgents } from '../../../redux/agency/agencySelectors';
import { RootState } from '../../../redux/rootTypes';
import { Agent } from '../../../types/agency';
import { getComparator, tableSort } from '../../../utils/tableSorter';
import TableHeader, { Order } from '../../TableHeader/TableHeader';
import classes from './AgencyTable.module.scss';

const headers = [
  { label: 'agency', id: 'agent_name' },
  { label: 'Anzahl Bestellungen', id: 'total_orders' },
  { label: 'Umsatz', id: 'total_price' },
  { label: 'Anzahl Cross-Sell Items', id: 'cross_sell_orders' },
  { label: 'Cross-Sell Umsatz', id: 'cross_sell_price' },
  { label: 'Cross-Sell conversion', id: 'cross_sell_conversion' },
];

const AgencyTable = () => {
  const { currentAgency } = useSelector((state: RootState) => state.agency);
  const agents = useSelector(selectAgencyAgents(currentAgency));
  const [order, setOrder] = useState<Order>('asc');
  const [sortBy, setSortBy] = useState<string>('');

  const handleRequestSort = (property: string) => {
    const isAsc = sortBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const mapCrossSellConversion = () =>
    agents
      ? agents.map(agent => {
          return {
            ...agent,
            cross_sell_conversion: (agent.cross_sell_orders / agent.total_orders) * 100,
          };
        })
      : [];

  return (
    <div className={classes.container}>
      <table className={classes.table_container}>
        <TableHeader
          sortBy={sortBy}
          headCells={headers}
          onRequestSort={handleRequestSort}
          order={order}
        />
        <tbody>
          {tableSort(mapCrossSellConversion(), getComparator(order, sortBy)).map((agent: Agent) => (
            <tr key={agent.agent_name}>
              <td>{agent.agent_name}</td>
              <td>{agent.total_orders}</td>
              <td>{agent.total_price} €</td>
              <td>{agent.cross_sell_orders}</td>
              <td>{agent.cross_sell_price} €</td>
              <td>{((agent.cross_sell_orders / agent.total_orders) * 100).toFixed(2)} %</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AgencyTable;
