import React from 'react';
import SegmentControlBtn from './SegmentControlBtn';

interface IPropsControl {
  dayMonthStringToday: string;
  dayMonthString: string;
}

export function GetControlBtnWithProps(props: IPropsControl) {
  return <SegmentControlBtn dateStringToday={props.dayMonthStringToday} dateString={props.dayMonthString} />;
}
