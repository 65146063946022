import { combineReducers, Reducer } from 'redux';
import { RootState } from './rootTypes';

import showsReducer, { initialState as showsInitialState } from './shows/showsReducer';
import productReducer, { initialState as productInitialState } from './products/productReducer';
import stockReducer, { initialState as stockInitialState } from './stock/stockReducer';
import salesReducer, { initialState as salesInitialState } from './sales/salesReducer';
import crossSellsReportReducer, { initialState as crossSalesInitialState } from './crossSells/crossSellsReducer';
import reklaOrdersReportReducer, { initialState as reklaOrdersInitialState } from './reklaOrders/reklaOrdersReducer';
import { pswReducer, initState as pswInitialState } from './psw/pswReducer';
import agenciesReducer, { initialState as agenciesInitialState } from './agency/agencyReducers';
import appReducer, { initialState as appInitialState } from './app/appReducer';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

export const reduxInitialState: RootState = {
  app: appInitialState,
  shows: showsInitialState,
  product: productInitialState,
  stock: stockInitialState,
  sales: salesInitialState,
  psw: pswInitialState,
  agency: agenciesInitialState,
  crossSellsReport: crossSalesInitialState,
  reklaOrdersReport: reklaOrdersInitialState,
};

export function createRootReducer(history: History) {
  return combineReducers({
    app: appReducer,
    shows: showsReducer,
    product: productReducer,
    stock: stockReducer,
    sales: salesReducer,
    router: connectRouter(history) as Reducer,
    psw: pswReducer,
    agency: agenciesReducer,
    crossSellsReport: crossSellsReportReducer,
    reklaOrdersReport: reklaOrdersReportReducer,
  });
}
