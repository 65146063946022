import { SalesCount } from '../../types/salesCount';

export interface SalesState {
  readonly productsSalesCount: SalesCount;
  readonly channelTotalRevenue: number;
  readonly salesCountLoading: boolean;
  readonly salesCountError?: boolean;
}

export enum SalesActionTypes {
  FETCH_SALES_COUNT = 'products/FETCH_SALES_COUNT',
  FETCH_SALES_COUNT_SUCCEEDED = 'products/FETCH_SALES_COUNT_SUCCEEDED',
  FETCH_SALES_COUNT_FAILED = 'products/FETCH_SALES_COUNT_FAILED',

  FETCH_SALES_COUNT_FOR_SHOWS = 'products/FETCH_SALES_COUNT_FOR_SHOWS',
  FETCH_SALES_COUNT_SUCCEEDED_FOR_SHOWS = 'products/FETCH_SALES_COUNT_SUCCEEDED_FOR_SHOWS',
  FETCH_SALES_COUNT_FAILED_FOR_SHOWS = 'products/FETCH_SALES_COUNT_FAILED_FOR_SHOWS',
}
