import SideBar from '../SideBar/SideBar';
import classes from './ShowDetails.module.scss';
import { Channel } from '../../types/tvShow';
import {
  DEFAULT_DATETIME_FORMAT,
  DELAY,
  REVENUE_DAY_FORMAT,
  STOCK_DELAY,
} from '../../constants/constants';
import dayjs from 'dayjs';
import { RootState } from '../../redux/rootTypes';
import useQueryParams from '../../hooks/useQueryParams';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FetchAllShows,
  FetchChannelDailyRevenue,
  FetchShowsRevenue,
} from '../../redux/shows/showsActions';
import { FetchProductsByShowId } from '../../redux/products/productsActions';
import { parseChannel } from '../../utils/parseChannel';
import ProductTable from '../ProductTable/ProductTable';
import { selectCurrentProduct } from '../../redux/products/productSelectors';
import VariantTable from '../VariantTable/VariantTable';
import { FetchProductsStock } from '../../redux/stock/stockAction';
import { FetchSalesCount } from '../../redux/sales/salesActions';

interface ShowDetailsProps {
  showId: string;
  channel: Channel;
  dateTime: string;
}

const ShowDetails = (props: ShowDetailsProps) => {
  const day = dayjs(props.dateTime).format(DEFAULT_DATETIME_FORMAT);
  const { channel = Channel.HSE24, date_time } =
    useQueryParams<{ channel: Channel; date_time: string }>();
  const currentProducts = useSelector(selectCurrentProduct);

  const today_revenue = dayjs(date_time).format(REVENUE_DAY_FORMAT);
  const dispatch = useDispatch();

  const { showsList } = useSelector((RootState: RootState) => RootState.shows);

  useEffect(() => {
    if (showsList.length === 0) {
      dispatch(new FetchAllShows(day));
    }
  }, [dispatch, showsList, day]);

  useEffect(() => {
    dispatch(new FetchProductsByShowId(props.showId));
  }, [dispatch, props.showId]);

  useEffect(() => {
    dispatch(new FetchChannelDailyRevenue(today_revenue));
  }, [dispatch, today_revenue]);

  useEffect(() => {
    if (currentProducts && currentProducts.length > 0) {
      const AllProductBaseNumber = currentProducts.map(({ base_product_no }) => base_product_no);

      dispatch(new FetchSalesCount(props.dateTime, AllProductBaseNumber));
      dispatch(new FetchShowsRevenue(today_revenue, parseChannel(channel)));

      const timer = setInterval(() => {
        dispatch(new FetchSalesCount(props.dateTime, AllProductBaseNumber));
        dispatch(new FetchShowsRevenue(today_revenue, parseChannel(channel)));
      }, DELAY);
      return () => clearInterval(timer);
    }
  }, [channel, currentProducts, dispatch, props.dateTime, today_revenue]);

  useEffect(() => {
    if (currentProducts && currentProducts.length > 0) {
      const AllProductBaseNumber = currentProducts.map(({ base_product_no }) => base_product_no);

      dispatch(new FetchProductsStock(AllProductBaseNumber));

      const timer = setInterval(() => {
        dispatch(new FetchProductsStock(AllProductBaseNumber));
      }, STOCK_DELAY);
      return () => clearInterval(timer);
    }
  }, [channel, currentProducts, dispatch, props.dateTime]);

  return (
    <div className={classes.details_container}>
      <SideBar />
      <div className={classes.body_container}>
        <h4> {currentProducts?.length} products in this broadcast</h4>
        <ProductTable channel={props.channel} dateTime={props.dateTime} />
        <h4>Variants</h4>
        <VariantTable />
      </div>
    </div>
  );
};

export default ShowDetails;
