import { ChannelsDailyRevenue, ShowsRevenue, TvShow } from '../../types/tvShow';
export interface ShowsState {
  readonly showsList: TvShow[];
  readonly error?: boolean;
  readonly loading: boolean;

  readonly shows_revenue: ShowsRevenue;
  readonly shows_revenue_loading: boolean;
  readonly shows_revenue_err: boolean;

  readonly channels_daily_revenue: ChannelsDailyRevenue;
  readonly channels_daily_revenue_loading: boolean;
  readonly channels_daily_revenue_error: boolean;
}

export enum ShowsActionTypes {
  FETCH_ALL_SHOWS = 'shows/FETCH_ALL_SHOWS',
  FETCH_SHOWS_DATA_FAILED = 'shows/FETCH_SHOWS_DATA_FAILED',
  FETCH_SHOWS_DATA_SUCCEEDED = 'shows/FETCH_SHOWS_DATA_SUCCEEDED',

  FETCH_SHOWS_REVENUE = 'shows/FETCH_SHOWS_REVENUE',
  FETCH_SHOWS_REVENUE_SUCCEEDED = 'shows/FETCH_SHOWS_REVENUE_SUCCEEDED',
  FETCH_SHOWS_REVENUE_FAILED = 'shows/FETCH_SHOWS_REVENUE_FAILED',

  FETCH_CHANNELS_DAILY_REVENUE = 'FETCH_CHANNELS_DAILY_REVENUE',
  FETCH_CHANNELS_DAILY_REVENUE_SUCCEEDED = 'FETCH_CHANNELS_DAILY_REVENUE_SUCCEEDED',
  FETCH_CHANNELS_DAILY_REVENUE_FAILED = 'FETCH_CHANNELS_DAILY_REVENUE_FAILED',
}
