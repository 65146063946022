import React, { FC } from 'react';
import { Location } from 'history';
import { Route } from 'react-router';
import { useDispatch } from 'react-redux';
import { LocalStorageKeys } from "../constants/localStorageKeys";
import { login } from "../cognito/redirect";
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { FetchToken } from "../redux/app/appActions";

interface Props {
  path: string;
  location: Location;
  exact?: boolean;
  authenticated: boolean;
  component: React.ComponentType;
}

const ProtectedRoute: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const jwtString = localStorage.getItem(LocalStorageKeys.IdToken);
  const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);

  if (!props.authenticated) {
    login();
    return null;
  }

  try {
    const jwtDetail = jwtDecode<JwtPayload>(jwtString || '');
    if (jwtDetail.exp && Date.now() >= jwtDetail.exp * 1000) {
      if (refreshToken) {
        dispatch(new FetchToken(refreshToken || ''));
      } else {
        login();
      }
    }
  } catch (error) {
    login();
  }

  return <Route exact={props.exact} path={props.path} component={props.component} />;
};

export default ProtectedRoute;
