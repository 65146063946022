import { useSelector } from 'react-redux';
import { CountryCode, PriceInfo } from '@hse24/shared-components';
import classes from '../ProductCard.module.scss';
import { selectSingleProductInfo } from '../../../redux/products/productSelectors';
import {
  selectTotalOrdered,
  selectVariantPrice,
  selectVariantReference,
} from '../../../redux/sales/salesSelectors';
import { getImageUrl } from '../../../utils/getImageUrl';
import { RootState } from '../../../redux/rootTypes';
import formatTime from '../../../utils/formatTime';
import Loading from '../../UI/Loading';
import Error from '../../UI/Error';

interface Props {
  productBaseNo: string;
}

const ProductInfoColumns = ({ productBaseNo }: Props) => {
  const productInfo = useSelector(selectSingleProductInfo(productBaseNo));
  const defaultVariantSku = productInfo?.product ? productInfo.product.default_variant_sku : '';
  const defaultVariantPrice = useSelector(selectVariantPrice(productBaseNo, defaultVariantSku));
  const defaultVariantReference = useSelector(
    selectVariantReference(productBaseNo, defaultVariantSku)
  );
  const { productOnAir, productOnAirLoading, productOnAirError } = useSelector(
    (RootState: RootState) => RootState.product
  );
  const totalOrdered = useSelector(selectTotalOrdered(productBaseNo));

  return productInfo?.product ? (
    <>
      <td>
        <div className={classes.main_info}>
          <img
            src={productInfo.product.media && getImageUrl(productInfo.product.media[0].uri)}
            alt=""
          />
          <div className={classes.info}>
            <h5 data-testid="name">{productInfo.product?.name?.long}</h5>
            <div>
              <p>{productInfo.product?.brand?.brand_name}</p>
            </div>
            <div className={classes.air_time}>
              {productOnAirLoading && <Loading />}
              {productOnAirError && <Error />}
              {!productOnAirLoading &&
                !productOnAirError &&
                productOnAir.product_nos === productBaseNo && (
                  <>
                    <b> Air time : {formatTime(productOnAir.on_air_time)}</b>
                    <b>
                      Floorsales/Min:
                      {(
                        (totalOrdered * defaultVariantPrice) /
                        (productOnAir.on_air_time / 60)
                      ).toFixed(2)}
                      €
                    </b>
                  </>
                )}
            </div>
          </div>
        </div>
      </td>
      <td>
        <PriceInfo
          className={classes.price}
          countryCode={CountryCode.DE}
          value={defaultVariantPrice}
          reference={{
            value: defaultVariantReference,
          }}
        />
      </td>
    </>
  ) : null;
};

export default ProductInfoColumns;
