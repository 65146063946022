import axios from 'axios';
import { addDotToNumber, getLeadingZero, returnDateStringForESB } from './NumberStringFormat';
import Config from '../../../config/config';
import { LocalStorageKeys } from "../../../constants/localStorageKeys";
const fetchdata = async (url: string) => {
  return await axios.get(url, {
    headers: {
      "X-Authorization": `Bearer ${window.localStorage.getItem(LocalStorageKeys.IdToken)}`,
    }
  });
};
const gb = (data: string[]) => {
  return data.join('/');
};
export class serviceCalls {
  endpoint: string;
  constructor() {
    this.endpoint = Config.mobileProducerSoftwareUrl;
  }
  urls(urlQuery: URLQuery) {
    const dayViewURL = gb([
      this.endpoint,
      'psw_dayView',
      returnDateStringForESB(urlQuery.dateDay, urlQuery.dateMonth, urlQuery.dateYear),
    ]);
    const showURL = gb([
      this.endpoint,
      `psw_show`,
      `${urlQuery.channel}`,
      returnDateStringForESB(urlQuery.dateDay, urlQuery.dateMonth, urlQuery.dateYear),
    ]);
    const onairURL = gb([
      this.endpoint,
      `psw_onair`,
      `${urlQuery.channel}`,
      returnDateStringForESB(urlQuery.dateDay, urlQuery.dateMonth, urlQuery.dateYear),
      new Date().getHours().toString(),
    ]);
    const productURL = gb([
      this.endpoint,
      `psw_product`,
      `${urlQuery.channel}/${urlQuery.group}`,
      returnDateStringForESB(urlQuery.dateDay, urlQuery.dateMonth, urlQuery.dateYear),
    ]);

    return {
      dayViewURL,
      showURL,
      onairURL,
      productURL,
    };
  }
  async dayView(urlQuery: URLQuery) {
    const url = this.urls(urlQuery).dayViewURL;
    return await fetchdata(url);
  }
  async onAir(urlQuery: URLQuery) {
    const url = this.urls(urlQuery).onairURL;
    return await fetchdata(url);
  }
  async product(urlQuery: URLQuery) {
    const url = this.urls(urlQuery).productURL;
    if (urlQuery.showProducts) {
      return await fetchdata(`${url}/${urlQuery.showHour}`)
    }

    return await fetchdata(url);
  }
  async show(urlQuery: URLQuery) {
    const url = this.urls(urlQuery).showURL;
    return await fetchdata(url);
  }
}

export function compareIDs(productID: string | number, onAirID: string | number) {
  return productID === onAirID;
}
export function parseShowData(json: IShowResponse) {
  const showdata: IShowDataEntry[] = [];
  if (Object.keys(json).length > 0) {
    for (let i = 0; i < json.shows.show.length; i++) {
      const interator = json.shows.show[i];
      const showDataEntry: IShowDataEntry = {
        showTitle: interator.show_title,
        showWrdCode: interator.wrd_code,
        showNks: interator.new_cust_hour,
        showHour: getLeadingZero(interator.show_hour),
        fsAct: Math.round(interator.fs_hour_act),
        fsPlan: Math.round(interator.fs_hour_mmfc),
        colorR: interator.colour_r,
        colorG: interator.colour_g,
        colorB: interator.colour_b,
        showHref: 'products.html',
        showFsActString: undefined,
        showFsPlanString: undefined,
        iconNameFs: undefined,
        iconColorFs: undefined,
      };
      showDataEntry.showFsActString = addDotToNumber(parseInt(showDataEntry.fsAct.toString()));
      showDataEntry.showFsPlanString = addDotToNumber(parseInt(showDataEntry.fsPlan.toString()));
      if (showDataEntry.fsAct > showDataEntry.fsPlan) {
        showDataEntry.iconNameFs = 'check circle outline';
        showDataEntry.iconColorFs = 'green';
      } else {
        showDataEntry.iconNameFs = 'remove circle';
        showDataEntry.iconColorFs = 'red';
      }
      showdata.push(showDataEntry);
    }
    const planArrays = fillPlanArrays(showdata);
    return {
      showData: showdata,
      overPlan: planArrays.overPlan,
      underPlan: planArrays.underPlan,
      inPlan: planArrays.inPlan,
    };
  }
}
///
export function parseDayViewData(json: IDayViewResponse) {
  const dayViewData: IDayViewEntry[] = [];
  const dayViewMainChanneldata: IDayViewEntry[] = [];

  for (let i = 0; i < json.day_views_response.day_view_responses.length; i++) {
    const interator = json.day_views_response.day_view_responses[i];
    const dayViewDataEntry: IDayViewEntry = {
      channel: interator.sha_code,
      nkd: interator.nkd,
      fsAct: Math.round(interator.fsi),
      fsPlan: Math.round(interator.fsp),
    };
    if (dayViewDataEntry.channel === 'ATV') {
      dayViewMainChanneldata.push(dayViewDataEntry);
    } else {
      dayViewData.push(dayViewDataEntry);
    }
  }
  let planArrays = fillPlanArrays(dayViewData);
  let planMainArrays = fillPlanArrays(dayViewMainChanneldata);
  let tempDate = new Date();
  //Value is hardcoded due to no endpoint. On air starts at 8 a.m.
  let currentAirHour = tempDate.getHours() - 8;
  if (currentAirHour < 0) {
    currentAirHour = 0;
  }
  //Air Time is from 8 a.m. until 11:59 p.m.
  let overallAirHours = 960; // 16 hours * 60

  //One additional hour on Sunday continuing in the night
  if (tempDate.getDay() === 0 || (tempDate.getDay() === 1 && tempDate.getHours() < 1)) {
    overallAirHours += 60;
  }

  let onAirMinutes = currentAirHour * 60 + tempDate.getMinutes();
  return {
    kpiTable: dayViewMainChanneldata.concat(dayViewData),
    overPlan: planArrays.overPlan,
    underPlan: planArrays.underPlan,
    inPlan: planArrays.inPlan,
    overPlanMainChannel: planMainArrays.overPlan,
    underPlanMainChannel: planMainArrays.underPlan,
    inPlanMainChannel: planMainArrays.inPlan,
    onAirMinutes: onAirMinutes,
    onAirMinutesOverall: overallAirHours,
  };
}

export function parseProductData(json: IProductResponse) {
  let productsArray = json.products.product;
  let productData: IPSWProductDataEntry[] = [];
  let nks = 0;
  if (json.products.nks) {
    nks = json.products.nks;
  }
  for (var i = 0; i < productsArray.length; i++) {
    productData.push(createProductDataEntry(productsArray[i], nks));
  }
  return productData;
}
function createProductDataEntry(jsonElement: IPSWProduct, nks: any) {
  let onAir = false;

  const productDataEntry = {
    title: jsonElement.desc,
    price: jsonElement.price.toString().replace('.', ','),
    pic: jsonElement.pic,
    label: jsonElement.label,
    onAir: onAir,
    nks: nks,
    productPlan: [
      Math.round(jsonElement.pfs),
      Math.round(parseInt(jsonElement.pfsm)),
      Math.round(parseInt(jsonElement.pnsm)),
      Math.round(jsonElement.psales),
      Math.round(parseInt(jsonElement.secs_fc)),
    ],
    productIs: [
      Math.round(jsonElement.ifs),
      Math.round(parseInt(jsonElement.ifsm)),
      Math.round(parseInt(jsonElement.insm)),
      Math.round(jsonElement.isales),
      Math.round(parseInt(jsonElement.secs_act)),
    ],
  };
  if (window.innerWidth < 350) {
    let titleWords = productDataEntry.title.split(' ');
    let lastWord = titleWords[titleWords.length - 1];

    if (lastWord.length > 11) {
      lastWord = lastWord.substr(0, 10) + '...';
      productDataEntry.title = '';
      for (var l = 0; l < titleWords.length - 2; l++) {
        productDataEntry.title += titleWords[l] + ' ';
      }
      productDataEntry.title += lastWord;
    }
  }
  return productDataEntry;
}
function fillPlanArrays(kpiData: IDayViewEntry[] | IShowDataEntry[]) {
  const underPlan: number[] = [];
  const inPlan: number[] = [];
  const overPlan: number[] = [];

  for (var i = 0; i < kpiData.length; i++) {
    if (kpiData[i].fsAct < kpiData[i].fsPlan) {
      underPlan.push(kpiData[i].fsPlan - kpiData[i].fsAct);
      inPlan.push(kpiData[i].fsAct);
      overPlan.push(0);
    } else if (kpiData[i].fsAct > kpiData[i].fsPlan) {
      underPlan.push(0);
      inPlan.push(kpiData[i].fsPlan);
      overPlan.push(kpiData[i].fsAct - kpiData[i].fsPlan);
    } else {
      underPlan.push(0);
      inPlan.push(kpiData[i].fsAct);
      overPlan.push(0);
    }
  }

  return {
    overPlan: overPlan,
    underPlan: underPlan,
    inPlan: inPlan,
  };
}
