import { useSelector } from 'react-redux';
import { SingleProductState } from '../../types/product';
import { Stock } from '../../types/stock';
import { RootState } from '../rootTypes';

const selectProducrStockVariant = (productBaseNo: string) => (state: RootState) => {
  const productInfo = state.product.productsByNo.find(
    (e: SingleProductState) => e.baseProductNo === productBaseNo
  );

  if (productInfo) {
    const productStockVariant = state.stock.productsStock.stock.filter((el: Stock) => {
      return productInfo.product?.variants.find(e => el.sku === e.sku);
    });
    return productStockVariant;
  } else {
    return [];
  }
};

export const selectTotalStock = (productBaseNo: string) => () => {
  const productStockVariant = useSelector(selectProducrStockVariant(productBaseNo));
  return productStockVariant.reduce(function (a, b) {
    return a + b.amount;
  }, 0);
};

export const selectReservedStock = (productBaseNo: string) => (state: RootState) => {
  const productStockVariant = useSelector(selectProducrStockVariant(productBaseNo));
  return productStockVariant.reduce(function (a, b) {
    return a + b.reserved_amount;
  }, 0);
};
