import React from 'react';
import { useSelector } from 'react-redux';
import { selectCrossSellsReport } from '../../../redux/crossSells/crossSellsSelectors';
import ReportTable from '../../ReportTable/ReportTable';

const headers = [
  { label: 'Agency', id: 'agent_username' },
  { label: 'Number of cross-sell orders', id: 'total_cross_sell_orders' },
  { label: 'Number of cross-sell items', id: 'total_cross_sell_items' },
  { label: 'Suspicious Days', id: 'suspicious_orders_days_count' },
];

const CrossSellsReportTable = () => {
  const crossSellsReport = useSelector(selectCrossSellsReport());

  return (
    <ReportTable
      headers={headers}
      data={crossSellsReport.agent_reports}
      uniqueKey="agent_username"
    />
  );
};

export default CrossSellsReportTable;
