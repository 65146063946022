import { ProductActionTypes, ProductState } from './productTypes';
import { Channel } from '../../types/tvShow';

export const initialState: ProductState = {
  productsByShowIdData: {
    id: 0,
    date_time: '',
    channel: Channel.HSE24,
    title: '',
    products: [],
    is_on_air: false,
    presenters: [],
  },
  productsByShowIdError: false,
  productsByShowIdLoading: true,
  productOnAirLoading: true,
  productOnAirError: false,
  productOnAir: { product_nos: '', on_air_time: 0 },
  productsByNo: [],
  currentVariantsSet: false,
  currentProductSku: '',
  currentVariants: [],
  currentProductDimension: [],
  currentProductStock: [],
  currentVariantSales: [],
};

const productReducer = (
  state = initialState,
  action: { type: string; payload?: any }
): ProductState => {
  switch (action.type) {
    case ProductActionTypes.FETCH_PRODUCTS_BY_SHOWID_SUCCEEDED:
      return {
        ...state,
        productsByNo: [],
        productsByShowIdLoading: false,
        productsByShowIdData: action.payload.productsByShowIdData,
      };

    case ProductActionTypes.FETCH_PRODUCTS_BY_SHOWID_FAILED:
      return {
        ...state,
        productsByShowIdError: true,
        productsByShowIdLoading: false,
        productsByNo: [],
      };

    case ProductActionTypes.FETCH_PRODUCT_ON_AIR_SUCCEEDED:
      return {
        ...state,
        productOnAir: action.payload.productOnAir,
        productOnAirLoading: false,
        productOnAirError: false,
      };

    case ProductActionTypes.FETCH_PRODUCT_ON_AIR_FAILED:
      return { ...state, productOnAirLoading: false, productOnAirError: true };

    case ProductActionTypes.FETCH_PRODUCT_BY_NO_SUCCEEDED:
      return {
        ...state,
        productsByNo: [
          ...state.productsByNo,
          {
            baseProductNo: action.payload.product.base_product_no,
            product: action.payload.product,
          },
        ],
      };

    case ProductActionTypes.FETCH_PRODUCT_BY_NO_FAILED:
      return {
        ...state,
        productsByNo: [
          ...state.productsByNo,
          { baseProductNo: action.payload.productsBaseNo, error: true },
        ],
      };

    case ProductActionTypes.CURRENT_VARIANTS_SET:
      return {
        ...state,
        currentVariantsSet: !state.currentVariantsSet,
        currentProductSku: action.payload.productsBaseNo,
      };

    case ProductActionTypes.CURRENT_VARIANTS:
      return { ...state, currentVariants: action.payload.variants };

    case ProductActionTypes.CLEAR_VARIANT:
      return { ...state, currentVariantsSet: false };

    case ProductActionTypes.CURRENT_PRODUCT_DIMENSIONS:
      return { ...state, currentProductDimension: action.payload.dimensions };

    case ProductActionTypes.CURRENT_PRODUCT_STOCK:
      return { ...state, currentProductStock: action.payload.stock };

    case ProductActionTypes.CURRENT_VARIANTS_SALES:
      return {
        ...state,
        currentVariantSales: action.payload.currentVariantsSales,
      };

    default:
      return state;
  }
};

export default productReducer;
