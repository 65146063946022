import showsSaga from './shows/showsSagas';
import productSaga from './products/productSaga';
import agencySaga from './agency/agencySaga';
import stockSaga from './stock/stockSaga';
import salesSaga from './sales/salesSagas';
import appSaga from './app/appSaga';
import crossSellsSaga from './crossSells/crossSellsSaga';
import reklaOrdersSaga from './reklaOrders/reklaOrdersSaga';

const rootSaga = [appSaga, showsSaga, productSaga, stockSaga, salesSaga, agencySaga, crossSellsSaga, reklaOrdersSaga];

export default rootSaga;
