import { useSelector } from 'react-redux';
import { Center } from '../../types/agency';
import { RootState } from '../rootTypes';

export const selectAgenciesName = () => (state: RootState) => {
  return state.agency.agencies.call_centers_sales.map((e: Center) => e.call_center);
};

export const selectAgencySales = (agency: string) => (state: RootState) => {
  return state.agency.agencies.call_centers_sales.find((e: Center) => e.call_center === agency);
};

export const selectAgencyAgents = (agency: string) => (state: RootState) => {
  return state.agency.agencies.call_centers_sales.find((e: Center) => e.call_center === agency)
    ?.agents_sales;
};

export const selectAgencyCrossSellConversion = (agency: string) => (state: RootState) => {
  const agencyInfo = state.agency.agencies.call_centers_sales.find(
    (e: Center) => e.call_center === agency
  );
  return (
    agencyInfo &&
    `${((agencyInfo?.cross_sell_orders / agencyInfo?.total_orders) * 100).toFixed(2)} %`
  );
};

export const selectAgenciesTotalRevenue = () => (state: RootState) => {
  return state.agency.agencies.call_centers_sales
    .reduce((n, { total_price }) => n + total_price, 0)
    .toFixed(2);
};

export const selectAgenciesTotalOrders = () => (state: RootState) => {
  return state.agency.agencies.call_centers_sales.reduce(
    (n, { total_orders }) => n + total_orders,
    0
  );
};

export const selectAgenciesTotalCrossSellOrders = () => (state: RootState) => {
  return state.agency.agencies.call_centers_sales.reduce(
    (n, { cross_sell_orders }) => n + cross_sell_orders,
    0
  );
};

export const selectAgenciesTotalCrossSellRevenue = () => (state: RootState) => {
  return state.agency.agencies.call_centers_sales.reduce(
    (n, { cross_sell_price }) => n + cross_sell_price,
    0
  );
};

export const selectAgenciesCrossSellConversion = () => (state: RootState) => {
  const agenciesTotalCrossSellOrders = useSelector(selectAgenciesTotalCrossSellOrders());
  const agenciesTotalOrders = useSelector(selectAgenciesTotalOrders());
  return `${((agenciesTotalCrossSellOrders / agenciesTotalOrders) * 100).toFixed(2)}%`;
};
