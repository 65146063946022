import dayjs from 'dayjs';
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../api/api';
import { DEFAULT_DATETIME_FORMAT } from '../../constants/constants';
import {
  FetchReklaOrdersReport,
  FetchReklaOrdersReportFailed,
  FetchReklaOrdersReportSucceeded,
} from './reklaOrdersActions';
import { ReklaOrdersReportActionTypes } from './reklaOrdersTypes';

export function* fetchReklaOrdersReport(action: FetchReklaOrdersReport) {
  const { payload } = action;

  try {
    const { data } = yield call(
      API.getReklaOrdersReport,
      dayjs(payload.startDate).startOf('day').format(DEFAULT_DATETIME_FORMAT),
      dayjs(payload.endDate).endOf('day').format(DEFAULT_DATETIME_FORMAT),
    );

    yield put(new FetchReklaOrdersReportSucceeded(data));
  } catch (err) {
    yield put(new FetchReklaOrdersReportFailed());
  }
}

export default function* fetchReklaOrdersReportSagaWatcher() {
  yield takeLatest(ReklaOrdersReportActionTypes.FETCH_REKLA_ORDERS_REPORT_DATA, fetchReklaOrdersReport);
}
