
export const onChannelChange = (newChannel: any, urlQuery: URLQuery) => {
  return { ...urlQuery, channel: newChannel };
};
//ToDo possibility to go forward with Date
export function onDateChange(dayDiff: number, urlQuery: URLQuery) {
  var tempDate;
  if (dayDiff === 0) {
    tempDate = new Date();
  } else {
    tempDate = new Date(urlQuery.dateYear, urlQuery.dateMonth - 1, urlQuery.dateDay);
    tempDate.setDate(tempDate.getDate() - dayDiff);
  }
  return {
    ...urlQuery,
    dateDay: tempDate.getDate(),
    dateMonth: tempDate.getMonth() + 1,
    dateYear: tempDate.getFullYear(),
  };
}
export function onMenuChange(productViewBool: boolean, urlQuery: URLQuery) {
  return { ...urlQuery, productView: productViewBool };
}
export function onShowProductsChange(showProductsBool: boolean, urlQuery: URLQuery) {
  return { ...urlQuery, showProducts: showProductsBool };
}
export function onGroupChange(newGroup: string, urlQuery: URLQuery) {
  return { ...urlQuery, group: newGroup };
}
export function onShowHourChange(newshowHour: string, urlQuery: URLQuery) {
  return { ...urlQuery, showHour: newshowHour };
}
