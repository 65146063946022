import React from 'react';
import { FiAlertCircle, FiBookmark, FiUserPlus } from 'react-icons/fi';
import { Icon } from 'semantic-ui-react';
import { getUrlQueryString } from '../data/UrlQueries';
import { onGroupChange, onMenuChange, onShowHourChange, onShowProductsChange } from '../data/onChange';
import { Heading, ListItem, Pane, UnorderedList } from 'evergreen-ui';
import Config from "../../../config/config";

interface IProps {
  showDataEntry: any;
  urlQuery: URLQuery;
}
export default function ListItemShow(props: IProps) {
  let dataEntry: Record<string, any> = {};
  if (props.showDataEntry) {
    dataEntry = props.showDataEntry;
  }
  let showItemQueryString = onShowHourChange(dataEntry.showHour, props.urlQuery);
  showItemQueryString = onShowProductsChange(true, showItemQueryString);
  showItemQueryString = onMenuChange(true, showItemQueryString);
  showItemQueryString = onGroupChange('SHOW', showItemQueryString);
  return (
    <Pane width={'100%'} display="flex" padding={20} border="default" margin="10px">
      <Pane>
        <Pane>
          <FiBookmark
            size={50}
            style={{
              color:
                'rgb(' + dataEntry.colorR + ', ' + dataEntry.colorG + ', ' + dataEntry.colorB + ')',
            }}
          />
        </Pane>
        <Pane textAlign="center">
          <Heading size={700} marginTop={9}>
            {dataEntry.showHour}
          </Heading>
        </Pane>
      </Pane>
      <Pane marginLeft={5}>
        <UnorderedList listStyle="none">
          <ListItem>
            <Heading size={500} marginTop={16}>
              <a href={`${Config.rootRoute}/?${getUrlQueryString(showItemQueryString)}`}>
                {dataEntry.showTitle}
              </a>
            </Heading>
          </ListItem>
          <Pane marginLeft={10}>
            <UnorderedList>
              <ListItem icon={<FiAlertCircle />}>{dataEntry.showWrdCode}</ListItem>
              <ListItem icon={<FiUserPlus />}>{dataEntry.showNks}</ListItem>
              <ListItem
                icon={
                  <Icon name={dataEntry.iconNameFs} color={dataEntry.iconColorFs} size="small" />
                }
              >
                {dataEntry.showFsActString}
                {dataEntry.fsPlan > 0 ? ' von ' + dataEntry.showFsPlanString : ''}
                {' €'}
              </ListItem>
            </UnorderedList>
          </Pane>
        </UnorderedList>
      </Pane>
    </Pane>
  );
}
