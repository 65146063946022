import React, { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from 'evergreen-ui';
import classes from './TableHeader.module.scss';
import cx from 'classnames';

export type Order = 'asc' | 'desc';

export interface TableHeaderProps {
  id: string;
  label: string;
}

interface Props {
  headCells: Array<TableHeaderProps>;
  sortBy: string;
  order: Order;
  onRequestSort: (property: string) => void;
}

const TableHeader = ({ order, onRequestSort, sortBy, headCells }: Props) => {
  const [icon, setIcon] = useState('');
  return (
    <thead className={classes.table_header}>
      <tr>
        {headCells.map(headCell => {
          return (
            <th key={headCell.id}>
              <div
                className={classes.icon_container}
                onClick={e => onRequestSort(headCell.id)}
                onMouseEnter={() => setIcon(headCell.id)}
                onMouseLeave={() => setIcon('')}
              >
                <span> {headCell.label} </span>
                {sortBy === headCell.id ? (
                  order === 'asc' ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  )
                ) : (
                  <ArrowUpIcon
                    className={cx(classes.icon, {
                      [classes.hover_icon]: icon === headCell.id,
                    })}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
