import { AppActionTypes, AppState } from "./appTypes";
import { LocalStorageKeys } from "../../constants/localStorageKeys";
import { AnyAction } from "redux";

export const initialState: AppState = {
  authError: false,
  token: window.localStorage.getItem(LocalStorageKeys.IdToken),
  refreshToken: window.localStorage.getItem(LocalStorageKeys.RefreshToken),
};

const appReducer = (state = initialState, action: AnyAction): AppState => {
  switch (action.type) {
    case AppActionTypes.FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        authError: false,
        token: action.payload.id_token,
        refreshToken: action.payload.refresh_token
      };
    case AppActionTypes.FETCH_TOKEN_FAILED:
      return {
        ...state,
        authError: true,
        token: null,
        refreshToken: null,
      };
    default:
      return state;
  }
};

export default appReducer;
