const HseIcon = () => (
  <svg width="61" height="24" viewBox="0 0 61 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.5 19.0405V23.7335C58.863 23.5735 48.71 22.6525 44.178 22.4065V1.59448C48.7 1.33248 57.218 0.58248 60.188 0.29648V4.97748C56.398 5.18748 52.545 5.39548 48.712 5.56848V9.99048C51.833 9.95048 54.952 9.90948 58.069 9.84848V14.1555C54.952 14.0955 51.833 14.0545 48.712 14.0145V18.4325C52.651 18.6105 56.611 18.8255 60.5 19.0405ZM41.022 16.0305C41.022 11.1795 35.772 10.2185 32.112 9.75848C30.557 9.56348 26.628 8.94848 26.628 7.51848C26.628 6.36248 28.063 5.40348 31.188 5.40348C33.622 5.40348 35.691 5.99148 37.575 7.23048C37.839 7.40348 38.184 7.34648 38.385 7.09648L40.609 4.34148C37.869 2.30648 34.64 1.57748 31.488 1.57748C26.059 1.57748 21.954 3.68948 21.954 7.60148C21.954 12.0005 26.701 13.0465 29.684 13.5285C32.47 13.9805 36.343 14.1535 36.343 16.0585C36.343 17.8025 33.24 18.5965 30.95 18.5965C29.246 18.5965 27.549 18.1865 25.083 16.9485C24.826 16.8195 24.519 16.8895 24.335 17.1185L22.052 19.9475C25.222 21.8075 28.888 22.4215 31.054 22.4215C36.093 22.4215 41.022 20.4655 41.022 16.0315V16.0305ZM13.953 10.0075C11.054 9.97348 8.158 9.93948 5.262 9.88748V0.72648C3.674 0.58448 2.087 0.435479 0.5 0.266479V23.7335C2.087 23.5655 3.674 23.4155 5.262 23.2745V14.1145C8.158 14.0625 11.054 14.0275 13.953 13.9945V22.6365C15.464 22.5475 16.976 22.4695 18.488 22.4065V1.59448C16.976 1.53048 15.464 1.45248 13.953 1.36448V10.0075Z"
      fill="#FF3C28"
    />
  </svg>
);

export default HseIcon;
