import dayjs from 'dayjs';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import API from '../../api/api';
import { OnAirResponse } from '../../types/product';
import { parseChannel } from '../../utils/parseChannel';
import {
  FetchProductsByShowIdSucceeded,
  FetchProductsByShowIdFailed,
  FetchOnAirProduct,
  FetchOnAirProductSucceeded,
  FetchProductByNo,
  FetchProductByNoSucceeded,
  FetchProductByNoFailed,
  FetchProductsByShowId,
  FetchOnAirProductFailed,
} from './productsActions';
import { ProductActionTypes } from './productTypes';

export function* getProductsByShowId(action: FetchProductsByShowId) {
  const { payload } = action;
  try {
    const { data } = yield call(API.getProductsByShowId, parseInt(payload.showId, 10));

    yield put(new FetchProductsByShowIdSucceeded(data));
  } catch (err) {
    yield put(new FetchProductsByShowIdFailed());
  }
}

export function* getOnAirProduct(action: FetchOnAirProduct) {
  const { payload } = action;
  const hour = dayjs(payload.dateTime).format('HH');
  const day = dayjs(payload.dateTime).format('DD-MM-YYYY');
  try {
    const onAirData: { data: OnAirResponse } = yield call(
      API.getOnAirTime,
      parseChannel(payload.channel),
      day,
      hour
    );
    const now = dayjs(new Date());
    const onAirStart = dayjs(
      onAirData.data.on_air_response.on_air_start,
      'YYYY-MM-DD HH:mm:ss'
    ).set('hour', onAirData.data.on_air_response.show_hour);
    const onAirTime = now.diff(onAirStart, 'second', true);
    yield put(
      new FetchOnAirProductSucceeded({
        product_nos: onAirData.data.on_air_response.product_id.toString(),
        on_air_time: onAirTime,
      })
    );
  } catch (err) {
    yield put(new FetchOnAirProductFailed());
  }
}

export function* getProductByNo(action: FetchProductByNo) {
  const { payload } = action;
  try {
    const { data } = yield call(API.getProductByNo, payload.productsBaseNo);
    yield put(new FetchProductByNoSucceeded(data));
  } catch (err) {
    yield put(new FetchProductByNoFailed(payload.productsBaseNo));
  }
}

export default function* productSagasWatcher() {
  yield takeLatest(ProductActionTypes.FETCH_PRODUCTS_BY_SHOWID, getProductsByShowId);
  yield takeLatest(ProductActionTypes.FETCH_PRODUCT_ON_AIR, getOnAirProduct);
  yield takeEvery(ProductActionTypes.FETCH_PRODUCT_BY_NO, getProductByNo);
}
