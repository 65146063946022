import useQueryParams from '../../hooks/useQueryParams';
import { Channel } from '../../types/tvShow';
import ChannelCard from '../ChannelCard/ChannelCard';
import classes from './Header.module.scss';

const Header = () => {
  const { channel = Channel.HSE24 } = useQueryParams<{ channel: string; dateTime: string }>();

  return (
    <div className={classes.channels}>
      <div className={classes.channels_wrapper}>
        {Object.values(Channel).map((value: Channel, index: number) => (
          <ChannelCard key={index} channel={value} current={channel === value} />
        ))}
      </div>
    </div>
  );
};

export default Header;
