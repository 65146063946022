import { useSelector } from 'react-redux';
import Header from '../Header/Header';
import classes from './SideBar.module.scss';
import { RootState } from '../../redux/rootTypes';
import useQueryParams from '../../hooks/useQueryParams';
import { Channel } from '../../types/tvShow';
import GoToLive from '../GoToLive/GoToLive';
import Switcher from '../Switcher/Switcher';
import { Button, Divider, EInputTypes } from '@hse24/shared-components';
import ShowInfo from '../ShowInfo/ShowInfo';
import Revenue from '../Revenue/Revenue';
import Loading from '../UI/Loading';
import Error from '../UI/Error';
import {
  selectChannelExpectedRevenue,
  selectShowExpectedRevenue,
} from '../../redux/shows/showsSelectors';
import { parseChannel } from '../../utils/parseChannel';
import { selectProductsSales } from "../../redux/sales/salesSelectors";

const SideBar = () => {
  const { channel = Channel.HSE24 } = useQueryParams<{ channel: Channel }>();
  const { showsList, loading, error } = useSelector((RootState: RootState) => RootState.shows);
  const {
    shows_revenue_loading,
    shows_revenue_err,
    channels_daily_revenue_error,
    channels_daily_revenue_loading,
  } = useSelector((RootState: RootState) => RootState.shows);
  const channelExpectedDailyRevenue = useSelector(selectChannelExpectedRevenue(parseChannel(channel)));

  const {
    productsByShowIdData,
    productsByShowIdLoading,
    productsByShowIdError
  } = useSelector((RootState: RootState) => RootState.product);
  const showExpectedRevenue = useSelector(selectShowExpectedRevenue(productsByShowIdData.date_time));
  const showProductsSales = useSelector(selectProductsSales(
    productsByShowIdData.products?.map(p => p.base_product_no) ?? []
  ))
  const channelSalesCount = useSelector((state: RootState) => state.sales.channelTotalRevenue);

  const ScrollToLiveProduct = () => {
    const slideNode = document.getElementById('scroll');
    slideNode && slideNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className={classes.side_bar_container}>
      <Header />
      {channels_daily_revenue_loading && shows_revenue_loading && <Loading />}
      {channels_daily_revenue_error && shows_revenue_err && <Error />}
      {!channels_daily_revenue_error && !channels_daily_revenue_loading && (
        <Revenue
          expectedRevenue={channelExpectedDailyRevenue ?? 0}
          generatedRevenue={channelSalesCount}
        />
      )}

      <Divider className={classes.divider} />

      {loading && <Loading />}
      {error && <Error />}
      {!loading && !error && (
        <Switcher channel={channel} showList={showsList} currentProgram={productsByShowIdData} />
      )}

      <Divider className={classes.divider} />

      <GoToLive channel={channel} />
      <Divider className={classes.divider} />

      {productsByShowIdLoading && <Loading />}
      {productsByShowIdError && <Error />}
      {!productsByShowIdError && !productsByShowIdLoading && (
        <ShowInfo
          title={productsByShowIdData.title}
          time={productsByShowIdData.date_time}
          presenters={productsByShowIdData.presenters}
          isOnAir={productsByShowIdData.is_on_air}
        />
      )}

      <Divider className={classes.divider} />

      {shows_revenue_loading && <Loading />}
      {shows_revenue_err && <Error />}
      {!shows_revenue_err && !shows_revenue_loading && (
        <Revenue
          expectedRevenue={showExpectedRevenue ?? 0}
          generatedRevenue={
            showProductsSales.reduce((a, v) => {
              return a + v.total_revenue
            }, 0)
          }
        />
      )}

      <Divider className={classes.divider} />

      <Button
        onClick={ScrollToLiveProduct}
        className={classes.go_to_air_product}
        inputType={EInputTypes.submit}
      >
        Go to on air product
      </Button>
    </div>
  );
};

export default SideBar;
