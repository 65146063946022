import { TvShow } from '../../types/tvShow';
import { ProductOnAir, Variant, Dimensions, SingleProductState } from '../../types/product';
import { Stock } from '../../types/stock';
import { VariantsSales } from '../../types/salesCount';

export interface ProductState {
  readonly productsByShowIdData: TvShow;
  readonly productsByShowIdError?: boolean;
  readonly productsByShowIdLoading: boolean;
  readonly productOnAir: ProductOnAir;
  readonly productOnAirLoading: boolean;
  readonly productOnAirError: boolean;
  readonly productsByNo: SingleProductState[];
  readonly currentVariants: Variant[];
  readonly currentVariantsSet: boolean;
  readonly currentProductSku: string;
  readonly currentProductDimension: Dimensions[];
  readonly currentProductStock: Stock[];
  currentVariantSales: VariantsSales[];
}

export enum ProductActionTypes {
  FETCH_PRODUCT_BY_NO = 'products/FETCH_ALL_PRODUCT_BY_NO',
  FETCH_PRODUCT_BY_NO_FAILED = 'products/FETCH_PRODUCT_BY_NO_FAILED',
  FETCH_PRODUCT_BY_NO_SUCCEEDED = 'products/FETCH_PRODUCT_BY_NO_SUCCEEDED',
  FETCH_PRODUCTS_BY_SHOWID = 'products/FETCH_PRODUCTS_BY_SHOWID',
  FETCH_PRODUCTS_BY_SHOWID_SUCCEEDED = 'products/FETCH_PRODUCTS_BY_SHOWID_SUCCEEDED',
  FETCH_PRODUCTS_BY_SHOWID_FAILED = 'products/FETCH_PRODUCTS_BY_SHOWID_FAILED',
  FETCH_PRODUCT_ON_AIR = 'products/FETCH_PRODUCT_ON_AIR',
  FETCH_PRODUCT_ON_AIR_SUCCEEDED = 'products/FETCH_PRODUCT_ON_AIR_SUCCEEDED',
  FETCH_PRODUCT_ON_AIR_FAILED = 'products/FETCH_PRODUCT_ON_AIR_FAILED',
  CURRENT_VARIANTS = 'CURRENT_VARIANTS',
  CURRENT_VARIANTS_SET = 'CURRENT_VARIANTS_SET',
  CURRENT_PRODUCT_STOCK = 'CURRENT_PRODUCT_STOCK',
  CURRENT_PRODUCT_DIMENSIONS = 'CURRENT_PRODUCT_DIMENSIONS',
  CLEAR_VARIANT = 'CLEAR_VARIANT',
  CURRENT_VARIANTS_SALES = 'CURRENT_VARIANTS_SALES',
}
