import dayjs from 'dayjs';
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../api/api';
import { AGENCY_DEFAULT_FORMAT } from '../../constants/constants';
import {
  CurrentAgency,
  FetchAgencies,
  FetchAgenciesFailed,
  FetchAgenciesLoading,
  FetchAgenciesSucceeded,
} from './agencyActions';
import { AgenciesActionTypes } from './agencyTypes';

export function* fetchAgencies(action: FetchAgencies) {
  const { payload } = action;

  try {
    yield put(new FetchAgenciesLoading());
    const { data } = yield call(
      API.getAgency,
      dayjs(payload.startDate).format(AGENCY_DEFAULT_FORMAT),
      dayjs(payload.endDate).format(AGENCY_DEFAULT_FORMAT)
    );
    yield put(new FetchAgenciesSucceeded(data));
    yield put(
      new CurrentAgency(data.call_centers_sales[0] ? data.call_centers_sales[0].call_center : '')
    );
  } catch (err) {
    yield put(new FetchAgenciesFailed());
  }
}

export default function* showsSagasWatcher() {
  yield takeLatest(AgenciesActionTypes.FETCH_AGENCIES_DATA, fetchAgencies);
}
