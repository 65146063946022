import { ProductsStock } from '../../types/stock';
export interface StockState {
  readonly productsStock: ProductsStock;
  readonly stockLoading: boolean;
  readonly stockError: boolean;
}

export enum StockActionType {
  FETCH_PRODUCTS_STOCK = 'stock/FETCH_PRODUCTS_STOCK',
  FETCH_PRODUCTS_STOCK_SUCCEEDED = 'stock/FETCH_PRODUCTS_STOCK_SUCCEEDED',
  FETCH_PRODUCTS_STOCK_FAILED = 'stock/FETCH_PRODUCTS_STOCK_FAILED',
}
