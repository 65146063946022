import { CrossSellsReportState, CrossSellsReportActionTypes } from './crossSellsTypes';

export const initialState: CrossSellsReportState = {
  report: {'date_range': {'start_date': '', end_date: ''}, agent_reports: [] },
  error: false,
  loading: true,
};

const crossSellsReportReducer = (
  state = initialState,
  action: { type: string; payload?: any }
): CrossSellsReportState => {
  switch (action.type) {
    case CrossSellsReportActionTypes.FETCH_CROSS_SELLS_REPORT_SUCCEEDED:
      return {
        loading: false,
        error: false,
        report: action.payload.report,
      };
    case CrossSellsReportActionTypes.FETCH_CROSS_SELLS_REPORT_FAILED:
      return { ...state, error: true, loading: false };

    case CrossSellsReportActionTypes.FETCH_CROSS_SELLS_REPORT_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default crossSellsReportReducer;
