import { useDispatch, useSelector } from 'react-redux';
import { Variant } from '../../types/product';
import { Stock } from '../../types/stock';
import { RootState } from '../../redux/rootTypes';
import VariantCard from '../ProductCard/VariantCard/VariantCard';
import classes from './VariantTable.module.scss';
import { useEffect } from 'react';
import { CurrentProductStock } from '../../redux/products/productsActions';
import { STOCK_DELAY } from '../../constants/constants';

const VariantTable = () => {
  const dispatch = useDispatch();
  const {
    currentVariantsSet,
    currentVariants,
    currentProductDimension,
    currentProductStock,
    currentVariantSales,
  } = useSelector((RootState: RootState) => RootState.product);
  const { productsStock } = useSelector((RootState: RootState) => RootState.stock);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(new CurrentProductStock(productsStock.stock));
    }, STOCK_DELAY);
    return () => clearInterval(timer);
  }, [dispatch, productsStock]);

  return (
    <div className={classes.variants_table}>
      <table>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Dimensions</th>
            <th>Price</th>
            <th>Stock</th>
            <th>TV-Verkauf </th>
            <th>Online-Verkauf </th>
            <th>UpSell-Verkauf</th>
            <th>Revenue</th>
          </tr>
        </thead>
        {currentVariantsSet && (
          <tbody>
            {currentVariants.map((variant: Variant, key: number) => (
              <VariantCard
                currentVariantSales={currentVariantSales}
                variant={variant}
                key={key}
                dimensions={currentProductDimension}
                stock={
                  currentProductStock &&
                  currentProductStock.find((element: Stock) => element.sku === variant.sku)
                }
              />
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default VariantTable;
