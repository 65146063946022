import { ProductPrice } from './prices';

export type Products = {
  products: Product[];
};

export interface Dimensions {
  type: string;
  values: Value[];
}

export interface Dimension {
  type: string;
  value: string;
}

export interface Value {
  value: string;
  code_hex: string;
  color_families?: string[];
}

export interface ProductName {
  short?: string;
  long: string;
}

export interface ProductBrand {
  brand_name?: string;
  productLineName?: string;
}

export interface Media {
  uri: string;
  mediaType: string;
}

export interface Variant {
  sku: string;
  price: ProductPrice;
  images?: Media[];
  dimensions: Dimension[];
  status?: string;
  delivery_time?: {
    delivery_message: string;
    shipping_type: string;
  };
}

export interface SalesDriver {
  name?: string;
}

export interface ShippingInfo {
  freeShipping: boolean;
}

export type Product = {
  base_product_no: string;
  name: ProductName;
  media?: Media[];
  defaultVariantSku: string;
  variants: Variant[];
  brand?: ProductBrand;
  salesdrivers?: SalesDriver[];
  shipping?: ShippingInfo;
  newInShop?: string;
  categoryPath?: string;
  status?: string;
  dimensions: Dimensions[];
  default_variant_sku: string;
};

export type ProductOnAir = {
  product_nos: string;
  on_air_time: number;
};
export interface SingleProductState {
  baseProductNo: string;
  error?: boolean;
  product?: Product;
}

export enum VariantType {
  COLOR = 'COLOR',
  AROMA = 'AROMA',
  ALLOY = 'ALLOY',
}

export type Host = {
  first_name: string;
  last_name: string;
  role: string;
  salutation: string;
};

export enum Role {
  EXP = 'EXP',
  HOS = 'HOS',
}

export type CurrentProduct = {
  base_product_no: string;
  type: string;
  position?: number;
};

export enum ProductType {
  OTHER = 'OTHER',
  HINT = 'HINT',
  RESERVE = 'RESERVE',
}

export type OnAirResponse = {
  on_air_response: {
    day_str: string;
    cha_code: string;
    show_hour: number;
    product_id: number;
    on_air_start: string;
  };
};
