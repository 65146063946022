import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../redux/rootTypes";
import { FetchToken } from "../redux/app/appActions";
import { login } from "../cognito/redirect";
import { LocalStorageKeys } from "../constants/localStorageKeys";
import useQueryParams from "../hooks/useQueryParams";
import Config from "../config/config";
import { push } from "connected-react-router";

function OAuthSignIn() {
  const queryParams = useQueryParams<{ code: string; }>();
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.app.token);
  const authError = useSelector((state: RootState) => state.app.authError);

  useEffect(() => {
    if (!token && !queryParams.code) {
      login();
    } else if (queryParams.code) {
      dispatch(new FetchToken(queryParams.code));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (token) {
      const route = localStorage.getItem(LocalStorageKeys.PathBeforeRedirect) || Config.rootRoute;
      localStorage.removeItem(LocalStorageKeys.PathBeforeRedirect);

      dispatch(push(route))
    }
  }, [token]); // eslint-disable-line

  if (authError) {
    return <p>An error occurred while login</p>;
  }

  return <span>Loading</span>;
}

export default OAuthSignIn;
