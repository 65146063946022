const formatTime = (seconds: number) => {
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const mDisplay = m.toString().length === 1 ? `0${m}` : m;
  const sDisplay = s.toString().length === 1 ? `0${s}` : s;
  return `${mDisplay}:${sDisplay}`;
};

export default formatTime;
