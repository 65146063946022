import React from 'react';
import styles from './Layout.module.scss';

interface Props {
  switcher: boolean;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal;
}

const Layout = (props: Props) => {
  return <div className={styles.wrapper}>{props.children}</div>;
};

export default Layout;
