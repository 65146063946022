import React from "react";

function scrollIntoView<T extends HTMLElement>(ref: React.RefObject<T>) {
  if (ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}

export default scrollIntoView;
