import React from 'react';
import { useSelector } from 'react-redux';
import { selectReklaOrdersReport } from '../../../redux/reklaOrders/reklaOrdersSelectors';
import ReportTable from '../../ReportTable/ReportTable';

const headers = [
  { label: 'Agency', id: 'agent_username' },
  { label: 'Product number', id: 'product_number' },
  { label: 'Order date', id: 'order_date' },
  { label: 'Order ID', id: 'order_id' },
  { label: 'Original order ID', id: 'original_order_id' },
];

const ReklaOrdersReportTable = () => {
  const reklaOrdersReport = useSelector(selectReklaOrdersReport());

  return (
    <ReportTable
      headers={headers}
      data={reklaOrdersReport.orders}
      uniqueKey="order_id"
    />
  );
};

export default ReklaOrdersReportTable;
