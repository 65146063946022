import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FetchReklaOrdersReport } from '../../../redux/reklaOrders/reklaOrdersActions';
import DateRangeSearch from '../../DateRangeSearch/DateRangeSearch';

const DAY_FORMAT_ONLY = 'YYYY-MM-DD';

interface ReklaOrdersReportSearchProps {
  loading: boolean;
}

const ReklaOrdersReportSearch = ({ loading }: ReklaOrdersReportSearchProps) => {
  const monthStart = dayjs().startOf('month').format(DAY_FORMAT_ONLY);
  const today = dayjs().format(DAY_FORMAT_ONLY);

  const [startDate, setStartDate] = useState(monthStart);
  const [endDate, setEndDate] = useState(today);

  const dispatch = useDispatch();

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    dispatch(new FetchReklaOrdersReport(startDate, endDate));
  };

  return (
    <DateRangeSearch
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      isLoading={loading}
      handleSubmit={handleSubmit}
    />
  );
};

export default ReklaOrdersReportSearch;
