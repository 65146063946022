import { Dimensions } from '../types/product';

export const getHexVariant = (color: string, dimensions?: Dimensions) => {
  if (dimensions) {
    const hexColor = dimensions.values.find(e => e.value === color);
    if (hexColor) {
      return hexColor.code_hex;
    } else {
      return null;
    }
  }
};
