import React from 'react';
import { Card, Label, Header, Progress, Icon } from 'semantic-ui-react';
import { addDotToNumber } from '../data/NumberStringFormat';
import { secondsToMinutesString } from '../data/NumberStringFormat';

interface IProps {
  productDataEntry: any;
}

export default function ProductCard(props: IProps) {
  var dataEntry: Record<string, any> = {};
  var progressTitles = ['Floorsales €', 'FS €/Min', 'NS €/Min', 'Verkäufe', 'Sendeminuten'];
  if (props.productDataEntry) {
    dataEntry = props.productDataEntry;
  }
  var cardProgressContents = [];
  for (var i = 0; i < progressTitles.length; i++) {
    if (dataEntry.productIs[i] > 0 || dataEntry.productPlan[i] > 0) {
      cardProgressContents.push(
        <CardProgressContent
          key={i}
          progressTitle={progressTitles[i]}
          planValue={dataEntry.productPlan[i]}
          isValue={dataEntry.productIs[i]}
        />,
      );
    }
  }

  var onAirTimeValue = '';
  var onAirSince = '';
  if (dataEntry.onAirSince !== undefined) {
    onAirTimeValue = dataEntry.onAirSince.split(' ')[1];
    onAirSince = onAirTimeValue.substr(0, onAirTimeValue.length - 5);
  }

  return (
    <Card>
      {dataEntry.onAir ? (
        <Label attached="top" color="red">
          {dataEntry.label} <Icon name="video camera"/>
          <div>seit {onAirSince}h</div>
        </Label>
      ) : (
        <Label attached="top">{dataEntry.label}</Label>
      )}

      <Card.Content>
        <Card.Header>{dataEntry.title}</Card.Header>
        <Card.Meta>
          <div className="left floated">{dataEntry.price} €</div>
          <div className="left floated">{dataEntry.pic}</div>
          {dataEntry.onAir && (
            <div className="left floated">
              <div className="left floated"/>
            </div>
          )}
        </Card.Meta>
      </Card.Content>
      {cardProgressContents}
      {/* <Card.Content>
        <Image src={imageSrc} size="tiny" />
      </Card.Content> */}
    </Card>
  );
}

type Props = {
  isValue: number;
  planValue: number;
  progressTitle: string;
};

function CardProgressContent(props: Props) {
  const percentage = Math.round((props.isValue / props.planValue) * 100);

  return (
    <Card.Content>
      {props.planValue > 0 ? (
        <div>
          <Header sub>{props.progressTitle}</Header>
          <Progress
            indicating
            progress="percent"
            size="small"
            percent={percentage}
            className="progressLabel"
          >
            {
              props.progressTitle === 'Sendeminuten'
                ? (<>{addDotToNumber(Number((secondsToMinutesString(props.isValue))))} / {addDotToNumber(Number((secondsToMinutesString(props.planValue))))}</>)
                : (<>{addDotToNumber(props.isValue)} / {addDotToNumber(props.planValue)}</>)
            }
          </Progress>
        </div>
      ) : (
        <Header sub>
          {props.progressTitle}: {props.isValue}
        </Header>
      )}
    </Card.Content>
  );
}
