import { SalesActionTypes, SalesState } from './salesTypes';

export const initialState: SalesState = {
  productsSalesCount: { items_count: 0, date_time: '', items: [] },
  channelTotalRevenue: 0,
  salesCountLoading: true,
  salesCountError: false,
};

const productReducer = (
  state = initialState,
  action: { type: string; payload?: any }
): SalesState => {
  switch (action.type) {
    case SalesActionTypes.FETCH_SALES_COUNT_SUCCEEDED:
      return {
        ...state,
        salesCountLoading: false,
        productsSalesCount: action.payload.productsSalesCount,
      };

    case SalesActionTypes.FETCH_SALES_COUNT_FAILED:
      return { ...state, salesCountError: true, salesCountLoading: false };

    case SalesActionTypes.FETCH_SALES_COUNT_SUCCEEDED_FOR_SHOWS:
      return {
        ...state,
        channelTotalRevenue: action.payload.channelTotalRevenue,
      };

    default:
      return state;
  }
};

export default productReducer;
