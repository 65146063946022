import classes from '../ProductCard.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootTypes';
import Loading from '../../UI/Loading';
import Error from '../../UI/Error';
import { selectReservedStock, selectTotalStock } from '../../../redux/stock/stockSelectors';

interface Props {
  productBaseNo: string;
}

const StockColumn = ({ productBaseNo }: Props) => {
  const { stockLoading, stockError } = useSelector((RootState: RootState) => RootState.stock);
  const totalStock = useSelector(selectTotalStock(productBaseNo));
  const reservedStock = useSelector(selectReservedStock(productBaseNo));

  return (
    <>
      {stockLoading && (
        <td>
          <Loading />
        </td>
      )}
      {stockError && (
        <td>
          <Error />
        </td>
      )}
      {!stockError && (
        <td>
          <div className={classes.stock}>
            <span>{totalStock}</span>
            <span>{reservedStock}</span>
          </div>
        </td>
      )}
    </>
  );
};

export default StockColumn;
