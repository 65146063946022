import { useSelector } from 'react-redux';
import { Variant } from '../../types/product';
import { Item } from '../../types/salesCount';
import { selectSingleProductInfo } from '../products/productSelectors';
import { RootState } from '../rootTypes';

export const selectProductSales = (productBaseNo: string) => (state: RootState) => {
  return state.sales.productsSalesCount.items.find(
    (item: Item) => item.base_product_number === productBaseNo
  );
};

export const selectProductsSales = (products: string[]) => (state: RootState) => {
  return state.sales.productsSalesCount.items.filter((item: Item) => {
    return products.includes(item.base_product_number)
  });
};

export const selectTvSales = (productBaseNo: string) => () => {
  const productSales = useSelector(selectProductSales(productBaseNo));
  return productSales ? productSales.sold_by_tv : 0;
};

export const selectOnlineShopSell = (productBaseNo: string) => () => {
  const productSales = useSelector(selectProductSales(productBaseNo));
  return productSales ? productSales.sold_in_shop : 0;
};

export const selectCrossSell = (productBaseNo: string) => () => {
  const productSales = useSelector(selectProductSales(productBaseNo));
  return productSales ? productSales.sold_as_cross_sell : 0;
};

export const selectTotalOrdered = (productBaseNo: string) => () => {
  const productSales = useSelector(selectProductSales(productBaseNo));
  return productSales ? productSales.total_ordered + productSales.sold_as_cross_sell : 0;
};

export const selectTotalRevenue = (productBaseNo: string) => () => {
  const productSales = useSelector(selectProductSales(productBaseNo));
  return productSales ? productSales.total_revenue : 0;
};

export const selectVariant = (productBaseNo: string, sku: string) => () => {
  const productInfo = useSelector(selectSingleProductInfo(productBaseNo));
  return productInfo?.product?.variants.find((variant: Variant) => {
    return variant.sku === sku;
  });
};

export const selectVariantPrice = (productBaseNo: string, sku: string) => () => {
  const variant = useSelector(selectVariant(productBaseNo, sku));
  return variant ? variant.price.value : 0;
};

export const selectVariantReference = (productBaseNo: string, sku: string) => () => {
  const variant = useSelector(selectVariant(productBaseNo, sku));
  return variant?.price.reference ? variant?.price.reference.value : 0;
};

export const selectTotalProductOrderedPrice = (productBaseNo: string, sku: string) => () => {
  const total_oredred = useSelector(selectTotalOrdered(productBaseNo));
  const variantPrice = useSelector(selectVariantPrice(productBaseNo, sku));

  return variantPrice * total_oredred;
};

export const selectVariantTvSales = (sku: string) => (state: RootState) => {
  const variantSales = state.product.currentVariantSales.find(e => e.sku === sku);
  return variantSales ? variantSales.sold_by_tv : 0;
};

export const selectVariantOnlineShopSell = (sku: string) => (state: RootState) => {
  const variantSales = state.product.currentVariantSales.find(e => e.sku === sku);
  return variantSales ? variantSales.sold_in_shop : 0;
};

export const selectVariantCrossSell = (sku: string) => (state: RootState) => {
  const variantSales = state.product.currentVariantSales.find(e => e.sku === sku);
  return variantSales ? variantSales.sold_as_cross_sell : 0;
};

export const selectVariantTotalRevenue = (sku: string) => (state: RootState) => {
  const variantSales = state.product.currentVariantSales.find(e => e.sku === sku);
  return variantSales ? variantSales.total_revenue : 0;
};
