import axios, { AxiosResponse } from 'axios';
import Config from '../config/config';
import { Locale } from '../utils/localization';
import { TvShow, TvShowsResponse } from '../types/tvShow';
import { OnAirResponse, ProductOnAir, Products } from '../types/product';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import { SalesCount } from '../types/salesCount';
import {ProductsStock} from "../types/stock";
import { CrossSellsReport } from '../types/crossSellsReport';
import { ReklaOrdersReport } from '../types/reklaOrdersReport';
import { login } from '../cognito/redirect';

export const locale = Locale.DE;

const authAxios = axios.create();
authAxios.interceptors.request.use(
  async config => {
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Authorization': `Bearer ${window.localStorage.getItem(LocalStorageKeys.IdToken)}`,
      },
    };
  },
  error => Promise.reject(error)
);

authAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
     login();
    }
    return Promise.reject(error);
  }
);

const Api = {
  getTvShowList: (
    startDate: string,
    endDate: string,
    appDevice = 'IPAD'
  ): Promise<AxiosResponse<TvShowsResponse>> => {
    return axios.get(`${Config.baseUrl}${Config.tvShowServicePath}`, {
      params: {
        date_start: startDate,
        date_end: endDate,
      },
      headers: { appDevice, locale },
    });
  },

  getProductsByShowId: (showId: number): Promise<AxiosResponse<TvShow>> => {
    return axios.get(`${Config.baseUrl}${Config.tvShowServicePath}/${showId}`, {
      headers: { appDevice: 'IPAD', locale },
    });
  },

  getProductByNo: (productBaseNo: string, appDevice = 'IPAD'): Promise<AxiosResponse<Products>> => {
    return axios.get(`${Config.baseUrl}${Config.productPath}/${productBaseNo}`, {
      headers: { appDevice, locale },
    });
  },

  getProductsStock: (
    productsBaseNo: string[],
    appDevice = 'IPAD'
  ): Promise<AxiosResponse<ProductsStock>> => {
    const date = new Date();
    return axios.get(`${Config.baseUrl}${Config.stockPath}`, {
      params: {
        base_product_no: productsBaseNo.join(','),
        uxs: date.getTime(),
        reserved_stock: true,
      },
      headers: { appDevice, locale, 'Cache-Control': 'no-cache' },
    });
  },

  getProductOnAir: (channel: string, appDevice = 'IPAD'): Promise<AxiosResponse<ProductOnAir>> => {
    return axios.get(`${Config.baseUrl}${Config.productOnAirPath}/${channel}?count=1`, {
      headers: { appDevice, locale },
    });
  },

  getSalesCount: (
    dateTime: string,
    productsBaseNo: string[]
  ): Promise<AxiosResponse<SalesCount>> => {
    return authAxios.get(`${Config.reportingService}${Config.saleCountPath}`, {
      params: {
        date_time: dateTime,
        base_product_numbers: productsBaseNo.join(','),
      },
      headers: { locale, 'Cache-Control': 'no-cache' },
    });
  },

  getShowsRevenue: (day: string, channel: string): Promise<AxiosResponse<TvShowsResponse>> => {
    return authAxios.get(`${Config.mobileProducerSoftwareUrl}/psw_show/${channel}/${day}`, {
      headers: { locale },
    });
  },

  getAgency: (startDate: string, endDate: string): Promise<AxiosResponse<TvShowsResponse>> => {
    return authAxios.get(`${Config.reportingService}/call-centers-sales-report`, {
      headers: { locale },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  getCrossSellsReport: (startDate: string, endDate: string): Promise<AxiosResponse<CrossSellsReport>> => {
    return authAxios.get(`${Config.reportingService}/cross-sell-items-report`, {
      headers: { locale },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  getReklaOrdersReport: (startDate: string, endDate: string): Promise<AxiosResponse<ReklaOrdersReport>> => {
    return authAxios.get(`${Config.reportingService}/rekla-orders-report`, {
      headers: { locale },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  getChannelsDailyRevenue: (day: string): Promise<AxiosResponse<any>> => {
    return authAxios.get(`${Config.mobileProducerSoftwareUrl}/psw_dayView/${day}`, {
      headers: { locale },
    });
  },

  getOnAirTime: (
    channel: string,
    day: string,
    hour: string
  ): Promise<AxiosResponse<OnAirResponse>> => {
    return authAxios.get(
      `${Config.mobileProducerSoftwareUrl}/psw_onair/${channel}/${day}/${hour}`,
      {
        headers: { locale, 'Cache-Control': 'no-cache' },
      }
    );
  },
};

export default Api;
