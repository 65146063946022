import { useDispatch, useSelector } from 'react-redux';
import { CurrentAgency } from '../../../redux/agency/agencyActions';
import { selectAgenciesName } from '../../../redux/agency/agencySelectors';
import { RootState } from '../../../redux/rootTypes';
import AgencyCard from '../AgencyCard/AgencyCard';
import AgencyHeader from '../AgencyHeader/AgencyHeader';

import classes from './AgencySideBar.module.scss';

const AgencySideBar = () => {
  const dispatch = useDispatch();
  const agencyNames = useSelector(selectAgenciesName());
  const { currentAgency } = useSelector((state: RootState) => state.agency);

  return (
    <div className={classes.container}>
      <AgencyHeader />
      <h1>{currentAgency} Callcenter Kürzel</h1>
      <div className={classes.agency_wrapper}>
        {agencyNames.map((agency: string) => (
          <div key={agency} onClick={() => dispatch(new CurrentAgency(agency))}>
            <AgencyCard name={agency} colored={currentAgency === agency} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgencySideBar;
