import { useEffect } from 'react';

import classes from './ProductCard.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootTypes';
import MoreIcon from '../../assets/MoreIcon';
import cx from 'classnames';
import {
  CurrentProductDimension,
  CurrentProductStock,
  CurrentVariants,
  CurrentVariantsSales,
  CurrentVariantsSet,
  FetchProductByNo,
} from '../../redux/products/productsActions';
import Loading from '../UI/Loading';
import NotFound from '../UI/NotFound';
import { AIR_TIME_DELAY } from '../../constants/constants';
import { Channel } from '../../types/tvShow';
import {
  selectSingleProductInfo,
  selectVariantsSales,
} from '../../redux/products/productSelectors';
import StockColumn from './ProductCardColumns/StockColumn';
import SalesColumns from './ProductCardColumns/SalesColumns';
import ProductInfoColumns from './ProductCardColumns/ProductInfoColumns';

interface Props {
  productBaseNo: string;
  channel: Channel;
  position?: number;
  type: string;
}

const ProductCard = ({ productBaseNo, channel, position, type }: Props) => {
  const dispatch = useDispatch();

  const { currentVariantsSet, currentProductSku, productOnAir } = useSelector(
    (RootState: RootState) => RootState.product
  );
  const { productsStock } = useSelector((RootState: RootState) => RootState.stock);
  const productInfo = useSelector(selectSingleProductInfo(productBaseNo));
  const productVariantsSales = useSelector(selectVariantsSales(productBaseNo));

  useEffect(() => {
    dispatch(new FetchProductByNo(productBaseNo));
    const timer = setInterval(() => {
      dispatch(new FetchProductByNo(productBaseNo));
    }, AIR_TIME_DELAY);
    return () => clearInterval(timer);
  }, [productBaseNo, dispatch, channel]);

  return (
    <>
      {!productInfo && (
        <tr className={classes.product_card}>
          <td>
            <Loading />
          </td>
        </tr>
      )}
      {productInfo?.error && (
        <tr className={classes.product_card}>
          <td>
            <div>
              <p>{productBaseNo}</p>
            </div>
          </td>
          <td>
            <div>
              <NotFound />
            </div>
          </td>
        </tr>
      )}
      {productInfo?.product && (
        <tr
          className={cx(classes.product_card, {
            [classes.pointer]: productInfo.product.variants.length > 1,
            [classes.toggled]:
              productInfo.product.variants.length > 1 &&
              currentVariantsSet &&
              currentProductSku === productInfo.product.base_product_no,
          })}
          id={
            productOnAir.product_nos === productInfo.product.base_product_no ? 'scroll' : undefined
          }
          onClick={() => {
            if (productInfo.product && productInfo.product.variants.length > 1) {
              dispatch(new CurrentVariantsSet(productInfo.product.base_product_no));
              dispatch(new CurrentProductDimension(productInfo.product.dimensions));
              dispatch(new CurrentVariants(productInfo.product.variants));
              dispatch(new CurrentProductStock(productsStock.stock));
              dispatch(new CurrentVariantsSales(productVariantsSales));
            }
          }}
        >
          <td>{position}</td>
          <td>
            <div className={classes.base_no}>
              {productOnAir.product_nos === productInfo.product.base_product_no && (
                <div className={classes.on_air} data-testid="onAir">
                  LIVE NOW
                </div>
              )}
              <p>{productInfo.product.base_product_no}</p>
            </div>
          </td>
          <ProductInfoColumns productBaseNo={productBaseNo} />
          <td>{type}</td>
          <StockColumn productBaseNo={productBaseNo} />
          <SalesColumns productBaseNo={productBaseNo} />
          <td>{productInfo.product.variants.length > 1 && <MoreIcon />}</td>
        </tr>
      )}
    </>
  );
};

export default ProductCard;
